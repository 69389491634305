/* You can add global styles to this file, and also import other style files */
/*================================================
Default CSS
=================================================*/
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
}

.footer-quick-links a {
  text-decoration: none;
  transition: 0.5s;
  color: #212121;
}
.footer-quick-links a:hover, .footer-quick-links a:focus {
  color: #086AD8;
  text-decoration: none;
}

.footer-info-contact a {
  text-decoration: none;
  transition: 0.5s;
  color: #212121;
}
.footer-info-contact a:hover, .footer-info-contact a:focus {
  color: #086AD8;
  text-decoration: none;
}

button {
  outline: 0 !important;
}

p {
  margin-bottom: 15px;
  line-height: 1.8;
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
p:last-child {
  margin-bottom: 0;
}

i {
  line-height: normal;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #212121;
  text-transform: capitalize;
  font-family: "Catamaran", sans-serif;
  font-weight: bold;
}
.h1:last-child, .h2:last-child, .h3:last-child, .h4:last-child, .h5:last-child, .h6:last-child, h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child {
  margin-bottom: 0;
}

.default-btn {
  border: none;
  line-height: initial;
  border-radius: 30px;
  transition: var(--transition);
  padding: 10px 32px 12px;
  position: relative;
  z-index: 1;
  opacity: 1 !important;
  font-size: 16px;
  font-family: var(--optionalFontFamily);
  font-weight: 600;
  background-color: #086AD8;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
}
.default-btn:hover {
  background-color: #10dce8;
  color: #ffffff;
}

.optional-btn {
  display: inline-block;
  padding: 13px 35px 14px;
  background-color: #10dce8;
  color: #ffffff;
  border-radius: 2px;
  margin-left: 16px;
  font-size: 15px;
  font-weight: 500;
}
.optional-btn:hover {
  background-color: #086AD8;
  color: #ffffff;
}

.section-title {
  text-align: center;
  margin-top: -5px;
  margin-bottom: 60px;
}
.section-title h2 {
  font-size: 40px;
  margin-bottom: 12px;
  position: relative;
  line-height: 1;
}
.section-title span {
  color: #086AD8;
}
.section-title p {
  max-width: 600px;
  margin: auto;
}
.section-title .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  margin: 20px auto 0;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.section-title .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}

@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(85px);
  }
}
@keyframes MOVE-BG {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(85px);
  }
}
.default-shape .shape-1 {
  position: absolute;
  right: 10%;
  top: 16%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}
.default-shape .shape-2 {
  position: absolute;
  left: 10%;
  top: 16%;
  z-index: -1;
}
.default-shape .shape-2 img {
  animation-name: rotateMe;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.default-shape .shape-3 {
  position: absolute;
  left: 5%;
  bottom: 25%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}
.default-shape .shape-4 {
  position: absolute;
  right: 10%;
  bottom: 25%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}
.default-shape .shape-5 {
  position: absolute;
  left: 50%;
  top: 10%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader-area {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #086AD8;
  z-index: 999999;
}
.preloader-area .preloader {
  width: 80px;
  height: 80px;
  display: inline-block;
  padding: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.preloader-area .preloader span {
  position: absolute;
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: #ffffff;
  -webkit-animation: preloader 1.3s linear infinite;
  animation: preloader 1.3s linear infinite;
}
.preloader-area .preloader span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
}

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes preloader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}
/*================================================
Navbar Area CSS
=================================================*/
.navbar-area .navbar {
  transition: 0.5s;
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}
.navbar-area .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.navbar-area .navbar .navbar-nav {
  margin-left: auto;
}
.navbar-area .navbar .navbar-nav .nav-item {
  position: relative;
  padding: 0;
}
.navbar-area .navbar .navbar-nav .nav-item a {
  color: #212121;
  text-transform: capitalize;
  font-size: 15.5px;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 14px;
  margin-right: 14px;
}
.navbar-area .navbar .navbar-nav .nav-item a:hover, .navbar-area .navbar .navbar-nav .nav-item a:focus, .navbar-area .navbar .navbar-nav .nav-item a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item a i {
  font-size: 16px;
  position: relative;
  top: 2px;
  display: inline-block;
  margin-left: -2px;
  margin-right: -4px;
}
.navbar-area .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.navbar-area .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.navbar-area .navbar .navbar-nav .nav-item:hover a, .navbar-area .navbar .navbar-nav .nav-item.active a {
  color: #086AD8;
  transition: 0.5s;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: absolute;
  border: none;
  top: 80px;
  left: 0;
  width: 250px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: capitalize;
  padding: 8px 15px;
  margin: 0;
  position: relative;
  color: #212121;
  font-size: 14.5px;
  font-weight: 600;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #086AD8;
  letter-spacing: 1px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a:hover::before, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a:focus::before, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a.active::before {
  display: none;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: -255px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #212121;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 220px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #212121;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #212121;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #212121;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #212121;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #212121;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
  margin-top: 0;
}
.navbar-area .navbar .others-options {
  margin-left: auto;
}
.navbar-area .navbar .others-options .option-item {
  color: #212121;
  display: inline-block;
  position: relative;
  line-height: 1;
}
.navbar-area .navbar .others-options .option-item .search-btn {
  cursor: pointer;
  transition: 0.5s;
  color: #212121;
  font-size: 20px;
  font-weight: 600;
}
.navbar-area .navbar .others-options .option-item .close-btn {
  cursor: pointer;
  display: none;
  transition: 0.5s;
  width: 20px;
  color: #212121;
  font-size: 18px;
}
.navbar-area .navbar .others-options .option-item .close-btn.active {
  display: block;
}
.navbar-area .navbar .others-options .burger-menu {
  width: 35px;
  height: auto;
  background-color: transparent;
  margin-left: 20px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 1px;
}
.navbar-area .navbar .others-options .burger-menu span {
  display: block;
  width: 35px;
  height: 3px;
  background: #212121;
  transition: 0.5s;
  border-radius: 30px;
  margin-left: auto;
  margin-bottom: 4px;
}
.navbar-area .navbar .others-options .burger-menu span:nth-child(3) {
  width: 28px;
  margin-left: auto;
  margin-bottom: 0;
}
.navbar-area .navbar .others-options .burger-menu:hover span {
  background: #086AD8;
}
.navbar-area .navbar .others-options .burger-menu:hover span:nth-child(3) {
  width: 35px;
}
.navbar-area .navbar .others-options .default-btn {
  margin-left: 20px;
}
.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.navbar-area.navbar-style-two {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 999;
}
.navbar-area.navbar-style-two.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.navbar-area.navbar-style-two.bg-color {
  background-color: rgba(255, 255, 255, 0.5);
}

.search-overlay {
  display: none;
}
.search-overlay.search-popup {
  position: absolute;
  top: 100%;
  width: 300px;
  right: 0;
  background: #ffffff;
  z-index: 2;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-top: 23px;
}
.search-overlay.search-popup .search-form {
  position: relative;
}
.search-overlay.search-popup .search-form .search-input {
  display: block;
  width: 100%;
  height: 50px;
  line-height: initial;
  border: 1px solid #eeeeee;
  color: #212121;
  outline: 0;
  transition: 0.5s;
  padding-top: 4px;
  padding-left: 10px;
}
.search-overlay.search-popup .search-form .search-input:focus {
  border-color: #086AD8;
}
.search-overlay.search-popup .search-form .search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: transparent;
  border: none;
  width: 50px;
  outline: 0;
  color: #666666;
  transition: 0.5s;
  padding: 0;
}
.search-overlay.search-popup .search-form .search-button:hover, .search-overlay.search-popup .search-form .search-button:focus {
  color: #086AD8;
}

.sidebar-modal {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  overflow: hidden;
}
.sidebar-modal .sidebar-modal-inner {
  position: absolute;
  right: -100%;
  top: 0;
  width: 30%;
  overflow-y: scroll;
  height: 100%;
  background-color: #ffffff;
  transition: 0.7s;
  z-index: 1;
  padding-top: 80px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}
.sidebar-modal .sidebar-modal-inner .close-btn {
  display: inline-block;
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 20px;
  transition: 0.5s;
  color: #212121;
  opacity: 0.66;
  cursor: pointer;
}
.sidebar-modal .sidebar-modal-inner .close-btn:hover {
  opacity: 1;
}
.sidebar-modal .sidebar-about-area {
  margin-bottom: 40px;
}
.sidebar-modal .sidebar-about-area .title h2 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}
.sidebar-modal .sidebar-about-area .title p {
  margin-top: 15px;
  font-size: 15px;
}
.sidebar-modal .sidebar-contact-feed {
  margin-bottom: 40px;
}
.sidebar-modal .sidebar-contact-feed h2 {
  margin-bottom: 25px;
  font-size: 22px;
}
.sidebar-modal .sidebar-contact-feed .contact-form {
  padding: 0;
  box-shadow: unset;
}
.sidebar-modal .sidebar-contact-feed .contact-form form .form-group {
  margin-bottom: 15px;
}
.sidebar-modal .sidebar-contact-feed .contact-form form .form-control {
  height: 50px;
  padding: 0 15px;
  line-height: initial;
  color: #212121;
  background-color: transparent;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  transition: 0.5s;
  box-shadow: unset !important;
  font-size: 14px;
  font-weight: 400;
}
.sidebar-modal .sidebar-contact-feed .contact-form form .form-control:focus {
  border-color: #086AD8;
}
.sidebar-modal .sidebar-contact-feed .contact-form form .form-control::placeholder {
  color: #666666;
}
.sidebar-modal .sidebar-contact-feed .contact-form form textarea.form-control {
  height: auto !important;
  padding-top: 15px;
}
.sidebar-modal .sidebar-contact-feed .contact-form .send-btn {
  margin-top: 10px;
}
.sidebar-modal .sidebar-contact-feed .contact-form .send-btn .send-btn-one {
  display: inline-block;
  padding: 14px 35px;
  background-color: #086AD8;
  color: #ffffff;
  border-radius: 2px;
  transition: 0.5s;
  border: none;
  font-size: 15px;
  font-weight: 600;
}
.sidebar-modal .sidebar-contact-feed .contact-form .send-btn .send-btn-one:hover {
  background-color: #10dce8;
  color: #ffffff;
}
.sidebar-modal .sidebar-contact-area .contact-info {
  text-align: center;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 {
  margin-bottom: 0;
  text-transform: lowercase;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a {
  display: inline-block;
  color: #086AD8;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:hover {
  color: #212121;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:not(:first-child) {
  color: #212121;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:not(:first-child):hover {
  color: #086AD8;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 span {
  display: block;
  color: #666666;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 20px;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li {
  display: inline-block;
  margin: 0 4px;
  padding-left: 0;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a {
  width: 35px;
  height: 35px;
  line-height: 32px;
  border: 1px solid #dadada;
  border-radius: 50%;
  color: #aba5a5;
  display: block;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a:hover {
  color: #ffffff;
  border-color: #086AD8;
  background-color: #086AD8;
}
.sidebar-modal.active {
  opacity: 1;
  visibility: visible;
}
.sidebar-modal.active .sidebar-modal-inner {
  right: 0;
}

.navbar-area .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (max-width: 767px) {
  .it-services-banner-image img {
    margin-left: 60px !important;
  }
  .contact-image img {
    margin-left: 35px !important;
  }
}
@media (max-width: 600px) {
  .it-services-banner-image img {
    margin-left: 60px !important;
  }
}
@media only screen and (max-width: 767px) {
  .navbar-area {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .navbar-area .navbar-light .navbar-toggler {
    border-color: #212121;
    border-radius: 0;
  }
  .navbar-area .collapse:not(.show).active {
    display: block;
  }
  .navbar-area .navbar .navbar-nav {
    margin: 15px 0 0;
    background-color: #086AD8;
    padding: 5px 15px;
    height: 350px;
    overflow-y: scroll;
  }
  .navbar-area .navbar .navbar-nav .nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-area .navbar .navbar-nav .nav-item a {
    font-size: 15px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    color: #ffffff !important;
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu {
    box-shadow: unset;
    position: relative;
    background-color: transparent !important;
    border: none;
    top: 0;
    left: 0;
    margin-top: 0 !important;
    width: 100%;
    z-index: 99;
    display: block;
    opacity: 1;
    visibility: visible;
    padding: 5px 20px 0;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a {
    font-size: 14px;
    padding-left: 0 !important;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    left: 0;
    top: 0;
    opacity: 1;
    visibility: visible;
  }
  .navbar-area .navbar .navbar-nav .nav-item:hover .dropdown-menu {
    top: 0;
  }
  .navbar-area .navbar .others-options {
    display: none;
  }
}
/*================================================
Banner Area CSS
=================================================*/
.main-banner-area {
  position: relative;
  overflow: hidden;
}

.home-sliders .home-item {
  position: relative;
  height: 850px;
  z-index: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.home-sliders .home-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #086AD8;
  opacity: 0.8;
}
.home-sliders .home-item .main-banner-content {
  position: relative;
  max-width: 630px;
}
.home-sliders .home-item .main-banner-content h1 {
  font-size: 70px;
  color: #ffffff;
  margin: 5px 0 15px 0;
}
.home-sliders .home-item .main-banner-content p {
  color: #ffffff;
  margin-bottom: 0;
  max-width: 600px;
  font-size: 16px;
}
.home-sliders .home-item .main-banner-content .banner-btn {
  margin-top: 30px;
}
.home-sliders .home-item .main-banner-content .banner-btn .default-btn {
  background-color: #ffffff;
  color: #212121;
}
.home-sliders .home-item .main-banner-content .banner-btn .default-btn:hover {
  color: #ffffff;
  background-color: #212121;
}
.home-sliders .home-item .main-banner-content .banner-btn .default-btn:not(:first-child) {
  margin-left: 15px;
  background-color: #10dce8;
  color: #212121;
}
.home-sliders .home-item .main-banner-content .banner-btn .default-btn:not(:first-child):hover {
  color: #ffffff;
  background-color: #212121;
}
.home-sliders .banner-image {
  position: absolute;
  top: 10px;
  right: 0;
  text-align: right;
}
.home-sliders .banner-image .banner-img {
  position: absolute;
  top: 70px;
  right: 0;
  text-align: right;
  max-width: 550px;
}
.home-sliders .banner-image img:nth-child(1) {
  animation: movescale 4s linear infinite;
}
.home-sliders.owl-theme .owl-nav {
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.home-sliders.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  font-size: 25px;
  margin: 0;
  padding: 0;
  background: #212121;
  border-radius: 2px;
  width: 55px;
  height: 55px;
  transition: 0.5s;
  border: none;
}
.home-sliders.owl-theme .owl-nav [class*=owl-] i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.home-sliders.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 20px;
}
.home-sliders.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #086AD8;
  color: #ffffff;
}
.home-sliders.owl-theme .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
}
.home-sliders.owl-theme .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
  margin: 0 5px;
  background: #ffffff;
  transition: 0.5s;
  border-radius: 50%;
  transform: scale(0.9);
}
.home-sliders.owl-theme .owl-dots .owl-dot:hover span, .home-sliders.owl-theme .owl-dots .owl-dot.active span {
  background-color: #10dce8;
}
.home-sliders.owl-theme .owl-dots .owl-dot.active span {
  transform: scale(1.2);
}
.home-sliders.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

@keyframes movescale {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
/*================================================
Features Area CSS
=================================================*/
.features-content {
  text-align: center;
  margin-bottom: 30px;
}
.features-content .icon {
  display: inline-block;
  height: 75px;
  width: 75px;
  line-height: 75px;
  background-color: #e7fdff;
  color: #10dce8;
  text-align: center;
  border-radius: 50px;
  transition: 0.5s;
  font-size: 35px;
}
.features-content .icon i.flaticon-security {
  position: relative;
  left: 3px;
}
.features-content h3 {
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 8px;
}
.features-content:hover .icon {
  background-color: #086AD8;
  color: #ffffff;
}

/*================================================
Creative Area CSS
=================================================*/
.creative-content h3 {
  font-size: 40px;
  margin-bottom: 16px;
  position: relative;
}
.creative-content h3::before {
  content: "";
  position: absolute;
}
.creative-content span {
  color: #086AD8;
}
.creative-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 16px;
}
.creative-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}

.skill-bar {
  margin-top: 30px;
  font-family: "Open Sans", sans-serif;
}
.skill-bar .progress-title-holder {
  position: relative;
  margin-bottom: 10px;
}
.skill-bar .progress-title {
  color: #212121;
  font-size: 18px;
  font-weight: 600;
}
.skill-bar .progress-number-wrapper {
  width: 100%;
  z-index: 10;
  line-height: 24px;
  height: 24px;
  text-transform: none;
  color: #ffffff;
  letter-spacing: 0px;
  font-weight: 600;
  font-style: normal;
  font-size: 11px;
}
.skill-bar .progress-number-mark {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  color: #212121;
  font-size: 16px;
  font-weight: 500;
}
.skill-bar .progress-content-outter {
  height: 6px;
  background-color: #e4e4e4;
  border-radius: 4px;
}
.skill-bar .progress-content {
  height: 6px;
  background: linear-gradient(to right, #bcf2f5, #b0eaf7, #a9e0f9, #a7d6f9, #abcbf6);
  border-radius: 4px;
  width: 0%;
}

.creative-image {
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.creative-image img {
  display: none;
}
.creative-image .video-btn {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 90px;
  background-color: #ffffff;
  border-radius: 50%;
  color: #212121;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 35px;
  text-align: center;
}
.creative-image .video-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  animation: ripple 2s linear 1s infinite;
}
.creative-image .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  animation: ripple 2s linear infinite;
}
.creative-image .video-btn:hover, .creative-image .video-btn:focus {
  background-color: #086AD8;
  color: #ffffff;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
/*================================================
Services Area CSS
=================================================*/
.services-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.services-section.bg-color {
  background-color: #f0f4f8;
  border-bottom: 1px solid #f3f3f3;
}
.services-section.bg-background {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.services-shape {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.single-services-box {
  text-align: center;
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  transition: 0.5s;
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-box .icon {
  transition: 0.5s;
  display: inline-block;
  height: 70px;
  width: 90px;
  line-height: 70px;
  background-color: #086AD8;
  text-align: center;
  font-size: 30px;
  color: #ffffff;
  border-radius: 30px 5px 30px 5px;
  transition: 0.5s;
}
.single-services-box .icon.bg-deb0fe {
  background-color: #deb0fe;
}
.single-services-box .icon.bg-79e8e2 {
  background-color: #79e8e2;
}
.single-services-box .icon.bg-fcc774 {
  background-color: #fcc774;
}
.single-services-box .icon.bg-84b7fd {
  background-color: #84b7fd;
}
.single-services-box .icon.bg-fe929f {
  background-color: #fe929f;
}
.single-services-box .icon.bg-2e1342 {
  background-color: #2e1342;
}
.single-services-box h3 {
  font-size: 22px;
  transition: 0.5s;
  margin-top: 25px;
  margin-bottom: 8px;
}
.single-services-box p {
  margin-bottom: 0;
  transition: 0.5s;
}
.single-services-box .read-btn {
  display: inline-block;
  margin-top: 15px;
  font-weight: 600;
  font-size: 15px;
}
.single-services-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 0%;
  background: #086AD8;
  transition: 0.5s;
  border-radius: 5px;
}
.single-services-box:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-box:hover::before {
  height: 100%;
}
.single-services-box:hover h3 {
  color: #ffffff;
}
.single-services-box:hover p {
  color: #ffffff;
}
.single-services-box:hover .read-btn {
  color: #ffffff;
}
.single-services-box:hover .icon {
  border-radius: 5px 30px 5px 30px;
  background-color: #ffffff !important;
  color: #086AD8 !important;
}

.single-services-two {
  text-align: center;
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  transition: 0.5s;
  border: 1px dashed #086AD8;
}
.single-services-two .icon {
  color: #086AD8;
  font-size: 50px;
  transition: 0.5s;
}
.single-services-two h3 {
  font-size: 25px;
  transition: 0.5s;
  margin-top: 15px;
  margin-bottom: 10px;
}
.single-services-two p {
  margin-bottom: 0;
  transition: 0.5s;
}
.single-services-two .read-btn {
  display: inline-block;
  margin-top: 15px;
  font-weight: 600;
  font-size: 15px;
}
.single-services-two::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 0%;
  background: #086AD8;
  border-radius: 0;
  transition: 0.5s;
  border-radius: 10px;
}
.single-services-two:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-two:hover::before {
  height: 100%;
}
.single-services-two:hover h3 {
  color: #ffffff;
}
.single-services-two:hover p {
  color: #ffffff;
}
.single-services-two:hover .read-btn {
  color: #ffffff;
}
.single-services-two:hover .icon {
  color: #ffffff;
}
.single-services-two.bg-fc9ba7 {
  border: 1px dashed #fc9ba7;
}
.single-services-two.bg-fc9ba7 .icon {
  color: #fc9ba7;
}
.single-services-two.bg-fc9ba7::before {
  background: #fc9ba7;
}
.single-services-two.bg-fc9ba7:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-two.bg-fc9ba7:hover::before {
  height: 100%;
}
.single-services-two.bg-fc9ba7:hover .icon {
  color: #ffffff;
}
.single-services-two.bg-2cdcf7 {
  border: 1px dashed #2cdcf7;
}
.single-services-two.bg-2cdcf7 .icon {
  color: #2cdcf7;
}
.single-services-two.bg-2cdcf7::before {
  background: #2cdcf7;
}
.single-services-two.bg-2cdcf7:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-two.bg-2cdcf7:hover::before {
  height: 100%;
}
.single-services-two.bg-2cdcf7:hover .icon {
  color: #ffffff;
}
.single-services-two.bg-009af0 {
  border: 1px dashed #009af0;
}
.single-services-two.bg-009af0 .icon {
  color: #009af0;
}
.single-services-two.bg-009af0::before {
  background: #009af0;
}
.single-services-two.bg-009af0:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-two.bg-009af0:hover::before {
  height: 100%;
}
.single-services-two.bg-009af0:hover .icon {
  color: #ffffff;
}
.single-services-two.bg-f4d62c {
  border: 1px dashed #f4d62c;
}
.single-services-two.bg-f4d62c .icon {
  color: #f4d62c;
}
.single-services-two.bg-f4d62c::before {
  background: #f4d62c;
}
.single-services-two.bg-f4d62c:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-two.bg-f4d62c:hover::before {
  height: 100%;
}
.single-services-two.bg-f4d62c:hover .icon {
  color: #ffffff;
}
.single-services-two.bg-1e2d75 {
  border: 1px dashed #1e2d75;
}
.single-services-two.bg-1e2d75 .icon {
  color: #1e2d75;
}
.single-services-two.bg-1e2d75::before {
  background: #1e2d75;
}
.single-services-two.bg-1e2d75:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-two.bg-1e2d75:hover::before {
  height: 100%;
}
.single-services-two.bg-1e2d75:hover .icon {
  color: #ffffff;
}

@-webkit-keyframes rotateMe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotateMe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@-webkit-keyframes animationFramesTwo {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(-73px, 1px) rotate(-36deg);
  }
  40% {
    transform: translate(-141px, -72px) rotate(-72deg);
  }
  60% {
    transform: translate(-83px, -122px) rotate(-108deg);
  }
  80% {
    transform: translate(40px, -72px) rotate(-144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes animationFramesTwo {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(-73px, 1px) rotate(-36deg);
  }
  40% {
    transform: translate(-141px, -72px) rotate(-72deg);
  }
  60% {
    transform: translate(-83px, -122px) rotate(-108deg);
  }
  80% {
    transform: translate(40px, -72px) rotate(-144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@-webkit-keyframes rotate3d {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes rotate3d {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@-webkit-keyframes moveScale {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.6);
  }
}
@keyframes moveScale {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.6);
  }
}
@-webkit-keyframes moveLeftBounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes moveLeftBounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@-webkit-keyframes moveBounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveBounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
/*================================================
Development Area CSS
=================================================*/
.development-text h3 {
  font-size: 40px;
  margin-bottom: 15px;
  position: relative;
}
.development-text h3::before {
  content: "";
  position: absolute;
}
.development-text span {
  color: #086AD8;
}
.development-text .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 16px;
}
.development-text .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}

.development-content {
  position: relative;
  padding-left: 90px;
  margin-top: 30px;
}
.development-content .icon {
  position: absolute;
  left: 0;
  top: 0;
}
.development-content .icon i {
  display: inline-block;
  height: 70px;
  width: 70px;
  line-height: 70px;
  background-color: #086AD8;
  color: #ffffff;
  text-align: center;
  border-radius: 50px;
  font-size: 35px;
}
.development-content .icon.bg-05dbcf i {
  background-color: #05dbcf;
}
.development-content .icon.bg-fec66f i {
  background-color: #fec66f;
}
.development-content .icon.bg-66a6ff i {
  background-color: #66a6ff;
}
.development-content span {
  color: #086AD8;
}
.development-content h3 {
  font-size: 22px;
  margin-bottom: 6px;
}

/*================================================
Video Section CSS
=================================================*/
.video-section {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 200px;
  padding-bottom: 200px;
}

.video-content {
  text-align: center;
  position: relative;
}
.video-content .video-btn {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 90px;
  background-color: #ffffff;
  border-radius: 50%;
  color: #212121;
  position: relative;
  z-index: 1;
  font-size: 30px;
}
.video-content .video-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  animation: ripple 2s linear 1s infinite;
}
.video-content .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  animation: ripple 2s linear infinite;
}
.video-content .video-btn:hover, .video-content .video-btn:focus {
  background-color: #086AD8;
  color: #ffffff;
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
/*================================================
Choose Area CSS
=================================================*/
.choose-content {
  position: relative;
  margin-top: 40px;
  padding: 20px 40px 20px 120px;
  background-color: #f5f9fd;
  border-radius: 50px;
  transition: 0.5s;
  z-index: 1;
}
.choose-content .icon {
  position: absolute;
  left: 30px;
  top: 26px;
  transition: 0.5s;
}
.choose-content .icon i {
  display: inline-block;
  height: 70px;
  width: 70px;
  line-height: 70px;
  background-color: #086AD8;
  color: #ffffff;
  text-align: center;
  border-radius: 50px;
  font-size: 35px;
  transition: 0.5s;
}
.choose-content h3 {
  font-size: 22px;
  margin-bottom: 8px;
  transition: 0.5s;
}
.choose-content::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 0%;
  height: 100%;
  background: #086AD8;
  border-radius: 0;
  transition: 0.5s;
  border-radius: 50px;
}
.choose-content p {
  transition: 0.5s;
}
.choose-content:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.choose-content:hover::before {
  width: 100%;
}
.choose-content:hover h3 {
  color: #ffffff;
}
.choose-content:hover p {
  color: #ffffff;
}
.choose-content:hover .icon i {
  background-color: #ffffff;
  color: #086AD8;
}
.choose-content:first-child {
  margin-top: 0;
}

.choose-text {
  position: relative;
  padding-left: 88px;
  margin-bottom: 30px;
}
.choose-text .icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 70px;
  width: 70px;
  background: linear-gradient(to right, #85edfe, #6ee0ff, #60d2ff, #5fc2ff, #6bb1ff);
  line-height: 70px;
  border-radius: 50px;
  color: #ffffff;
  font-size: 30px;
  text-align: center;
}
.choose-text h3 {
  font-size: 22px;
  margin-bottom: 8px;
}
.choose-text:last-child {
  margin-bottom: 0;
}

.image {
  text-align: center;
}

/*================================================
Projects Area CSS
=================================================*/
.single-projects-box {
  position: relative;
  margin-bottom: 30px;
  text-align: center;
}
.single-projects-box.two {
  margin-bottom: 0;
}
.single-projects-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #086AD8;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.single-projects-box .projects-content {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  right: 0;
  color: #ffffff;
  margin-top: 15px;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.single-projects-box .projects-content h3 {
  color: #ffffff;
  font-size: 22px;
  transition: 0.5s;
  margin-bottom: 6px;
}
.single-projects-box .projects-content span {
  color: #ffffff;
  font-weight: 600;
  transition: 0.5s;
}
.single-projects-box .link-btn {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 2;
}
.single-projects-box .projects-image img {
  width: 100%;
}
.single-projects-box:hover::before {
  opacity: 0.8;
  visibility: visible;
}
.single-projects-box:hover .projects-content {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel {
  display: block;
  width: 100%;
  z-index: 1;
}

.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

*, *::before, *::after {
  box-sizing: border-box;
}

/*================================================
Projects Wrap Area CSS
=================================================*/
.projects-wrap-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.projects-wrap-area .container-fluid {
  overflow: hidden;
  left: calc((100% - 1320px) / 2);
  position: relative;
}

.projects-wrap-content {
  padding-right: 70px;
}
.projects-wrap-content span {
  display: block;
  color: var(--eggBlueColor);
  text-transform: uppercase;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 15px;
  font-family: var(--optionalFontFamily);
}
.projects-wrap-content h2 {
  margin-bottom: 15px;
  font-size: 45px;
  font-weight: 700;
}
.projects-wrap-content p {
  margin-bottom: 0;
}
.projects-wrap-content .projects-wrap-btn {
  margin-top: 25px;
}
.projects-wrap-content .projects-wrap-btn .btn-primary::before {
  background: var(--eggBlueColor);
}
.projects-wrap-content .projects-wrap-btn .btn-primary::after {
  background: var(--mediumPurpleColor);
}

.single-projects-card {
  margin-bottom: 30px;
  transition: var(--transition);
  background-color: var(--whiteColor);
}
.single-projects-card a img {
  transition: var(--transition);
}
.single-projects-card .projects-content {
  transition: var(--transition);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  padding: 30px 35px;
}
.single-projects-card .projects-content span {
  display: block;
  color: var(--paragraphColor);
  margin-bottom: 12px;
  font-size: 13.8px;
  font-weight: 600;
}
.single-projects-card .projects-content h3 {
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 700;
}
.single-projects-card .projects-content p {
  margin-top: 15px;
  margin-bottom: 0;
}
.single-projects-card .projects-content .read-more-btn {
  margin-top: 15px;
  color: var(--eggBlueColor);
}
.single-projects-card:hover a img {
  opacity: 0.75;
}

.projects-wrap-slides.owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
}
.projects-wrap-slides.owl-theme .owl-nav [class*=owl-] {
  display: block;
  margin: 0;
  text-align: center;
  height: 50px;
  line-height: 50px;
  width: 50px;
  background: var(--whiteColor);
  color: var(--mainColor);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0;
  transition: var(--transition);
  position: relative;
  z-index: 1;
  font-weight: 600;
  font-size: 18.5px;
  font-family: var(--optionalFontFamily);
}
.projects-wrap-slides.owl-theme .owl-nav [class*=owl-] i {
  position: relative;
  top: -1px;
}
.projects-wrap-slides.owl-theme .owl-nav [class*=owl-] i::before {
  font-size: 16.5px;
}
.projects-wrap-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--whiteColor);
  background-color: var(--mediumPurpleColor);
}

.about-wrap-content {
  padding-left: 50px;
}
.about-wrap-content span {
  display: block;
  color: var(--eggBlueColor);
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 15px;
  font-family: var(--optionalFontFamily);
}
.about-wrap-content h3 {
  margin-bottom: 0;
  font-size: 45px;
  font-weight: 700;
}
.about-wrap-content p {
  margin-bottom: 0;
  margin-top: 15px;
}
.about-wrap-content .wrap-list {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0;
}
.about-wrap-content .wrap-list li {
  color: var(--blackColor);
  font-weight: 600;
  list-style-type: none;
  margin-bottom: 20px;
  position: relative;
  padding: 5px 0 5px 38px;
}
.about-wrap-content .wrap-list li:last-child {
  margin-bottom: 0;
}
.about-wrap-content .wrap-list li i {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background-color: #086AD8;
  color: white;
  border-radius: 50px;
  text-align: center;
  font-size: 15px;
  transition: var(--transition);
}
.about-wrap-content .wrap-list li:hover i {
  background-color: #0dcaf0;
  color: #ffffff;
}
.about-wrap-content .about-btn {
  margin-top: 30px;
}
.about-wrap-content .about-btn .btn-primary::before {
  background: var(--eggBlueColor);
}
.about-wrap-content .about-btn .btn-primary::after {
  background: var(--mediumPurpleColor);
}

.about-wrap-image-with-style {
  position: relative;
  z-index: 1;
  border-radius: 15px;
  padding-top: 65px;
  padding-bottom: 35px;
  padding-left: 35px;
  padding-right: 65px;
}
.about-wrap-image-with-style img {
  border-radius: 15px;
}
.about-wrap-image-with-style .wrap-shape {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.about-wrap-image-with-style .wrap-shape img {
  border-radius: 15px;
}
.about-wrap-image-with-style .wrap-shape-2 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.about-wrap-image-with-style .wrap-shape-2 img {
  border-radius: 15px;
}

.choose-wrap-content {
  padding-right: 50px;
}
.choose-wrap-content span {
  display: block;
  color: var(--eggBlueColor);
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 15px;
  font-family: var(--optionalFontFamily);
}
.choose-wrap-content h3 {
  margin-bottom: 0;
  font-size: 45px;
  font-weight: 700;
}
.choose-wrap-content p {
  margin-bottom: 0;
  margin-top: 15px;
}
.choose-wrap-content .wrap-list {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0;
}
.choose-wrap-content .wrap-list li {
  color: var(--blackColor);
  font-weight: 600;
  list-style-type: none;
  margin-bottom: 20px;
  position: relative;
  padding: 5px 0 5px 38px;
}
.choose-wrap-content .wrap-list li:last-child {
  margin-bottom: 0;
}
.choose-wrap-content .wrap-list li i {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background-color: var(--mediumPurpleColor);
  color: var(--whiteColor);
  border-radius: 50px;
  text-align: center;
  font-size: 15px;
  transition: var(--transition);
}
.choose-wrap-content .wrap-list li:hover i {
  background-color: var(--eggBlueColor);
}
.choose-wrap-content .choose-btn {
  margin-top: 30px;
}
.choose-wrap-content .choose-btn .btn-primary::before {
  background: var(--eggBlueColor);
}
.choose-wrap-content .choose-btn .btn-primary::after {
  background: var(--mediumPurpleColor);
}

.cursor-pointer-none {
  cursor: none !important;
}

.crimo-nav .navbar .navbar-nav .nav-item {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
}

/*================================================
Features Style Area CSS
=================================================*/
.features-style-inner-box {
  position: relative;
  z-index: 1;
  background-color: white;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  border-radius: 30px;
  padding-top: 100px;
  padding-bottom: 70px;
  padding-left: 70px;
  padding-right: 70px;
}

.col-lg-4:last-child .features-style-card::before {
  display: none;
}

.expert {
  width: 80%;
}

.owl-prev {
  position: absolute;
  right: 100%;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #086AD8;
}
.owl-prev:hover {
  color: #ffffff;
  background-color: #086AD8;
}

.owl-next {
  position: absolute;
  left: 100%;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #086AD8;
}
.owl-next:hover {
  color: #ffffff;
  background-color: #086AD8;
}

.features-style-card {
  max-width: 310px;
  margin: 0 auto 30px;
  position: relative;
  z-index: 1;
}
.features-style-card::before {
  content: "";
  position: absolute;
  top: 0;
  right: -42px;
  width: 1px;
  height: 100%;
  background-color: #DFDFDF;
}
.features-style-card .features-image {
  margin-bottom: 25px;
}
.features-style-card .features-content h3 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 15px;
}
.features-style-card .features-content p {
  margin-bottom: 15px;
}
.features-style-card .features-content .features-btn {
  font-size: 18px;
  font-weight: 600;
  color: var(--eggBlueColor);
  font-family: var(--optionalFontFamily);
}
.features-style-card.text-start {
  margin: 0 auto 30px 0;
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
}
.services-area .pagination-area {
  margin-bottom: 30px;
}

.single-services-box {
  text-align: center;
  margin-bottom: 30px;
  background-color: #f8fbf8;
  transition: var(--transition);
  border-bottom: 3px solid var(--mainColor);
  border-radius: 5px;
  padding: 25px;
}
.single-services-box .icon {
  margin-bottom: 18px;
  position: relative;
  display: inline-block;
  z-index: 1;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border: 1px solid #dcffd0;
  border-radius: 50%;
  color: var(--mainColor);
  transition: var(--transition);
}
.single-services-box .icon i.flaticon-plugin {
  top: -2px;
  left: -2px;
  position: relative;
}
.single-services-box .icon i::before {
  font-size: 32px;
}
.single-services-box .icon::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #daffce;
  z-index: -1;
  border-radius: 50%;
  margin: 5px;
  transition: var(--transition);
}
.single-services-box h3 {
  margin-bottom: 0;
  font-size: 23px;
  font-weight: 700;
}
.single-services-box p {
  margin-top: 13px;
  margin-bottom: 0;
}
.single-services-box .read-more-btn {
  margin-top: 15px;
}
.single-services-box:hover {
  background-color: var(--whiteColor);
  box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
  transform: translateY(-3px);
}
.single-services-box:hover .icon {
  border-color: var(--mainColor);
  color: var(--whiteColor);
}
.single-services-box:hover .icon::before {
  background: var(--mainColor);
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-overview {
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 50px;
}
.services-details-overview:first-child {
  margin-top: 0;
}
.services-details-overview .services-details-desc {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}
.services-details-overview .services-details-desc h3 {
  margin-bottom: 15px;
  font-size: 23px;
  font-weight: 700;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 25px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item {
  display: block;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: var(--whiteColor);
  margin-bottom: 10px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title {
  padding: 12px 20px 12px 51px;
  color: var(--blackColor);
  position: relative;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  font-size: 15px;
  font-weight: 700;
  font-family: var(--optionalFontFamily);
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: var(--mainColor);
  color: var(--whiteColor);
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 13px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title.active {
  border-bottom-color: #eeeeee;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title.active i::before {
  content: "\f068";
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px 15px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-content.show {
  display: block;
}
.services-details-overview .services-details-image {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

/*================================================
IT Services Banner Area CSS
=================================================*/
.it-services-banner-area {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding-top: 120px;
  padding-bottom: 130px;
}
.it-services-banner-area .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}

.it-services-banner-image {
  max-width: 650px;
}
.it-services-banner-image img {
  width: 70%;
  margin-left: 178px;
}

.it-services-banner-content {
  max-width: 650px;
}
.it-services-banner-content h1 {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 22px;
}
.it-services-banner-content p {
  margin-bottom: 0;
}
.it-services-banner-content .btn-box {
  margin-top: 30px;
}
.it-services-banner-content .btn-box .btn-primary::before {
  background: white;
}
.it-services-banner-content .btn-box .btn-primary::after {
  background: mediumpurple;
}
.it-services-banner-content .btn-box .btn-light {
  margin-left: 15px;
}

/*================================================
Clients Area CSS
=================================================*/
.clients-section.bg-background {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.clients-section.bg-background .section-title h2 {
  color: #ffffff;
}
.clients-section.bg-background .section-title p {
  color: #ffffff;
}
.clients-section.bg-background .clients-slider .clients-item p {
  color: #ffffff;
}
.clients-section.bg-background .clients-slider .clients-item .clients-content h3 {
  color: #ffffff;
}
.clients-section.bg-background .clients-slider .clients-item .clients-content span {
  color: #ffffff;
}

.team-slider.owl-theme {
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
}
.team-slider.owl-theme .owl-dots {
  line-height: 1;
}
.team-slider.owl-theme .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
  margin: 0 5px;
  background: #c8c3c3;
  transition: 0.5s;
  border-radius: 50%;
  transform: scale(0.9);
}
.team-slider.owl-theme .owl-dots .owl-dot:hover span, .team-slider.owl-theme .owl-dots .owl-dot.active span {
  background-color: #086AD8;
}
.team-slider.owl-theme .owl-dots .owl-dot.active span {
  transform: scale(1.2);
}
.team-slider.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px;
}

.clients-slider.owl-theme {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}
.clients-slider.owl-theme .owl-dots {
  line-height: 1;
}
.clients-slider.owl-theme .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
  margin: 0 5px;
  background: #c8c3c3;
  transition: 0.5s;
  border-radius: 50%;
  transform: scale(0.9);
}
.clients-slider.owl-theme .owl-dots .owl-dot:hover span, .clients-slider.owl-theme .owl-dots .owl-dot.active span {
  background-color: #086AD8;
}
.clients-slider.owl-theme .owl-dots .owl-dot.active span {
  transform: scale(1.2);
}
.clients-slider.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px;
}

.clients-item {
  text-align: center;
}
.clients-item .icon i {
  display: inline-block;
  height: 80px;
  width: 80px;
  line-height: 80px;
  background-color: #086AD8;
  color: #ffffff;
  font-size: 40px;
  border-radius: 50px;
}
.clients-item p {
  margin-top: 20px;
  font-style: italic;
  font-size: 17px;
}
.clients-item .clients-content {
  margin-top: 65px;
  position: relative;
}
.clients-item .clients-content::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 30px;
  background-color: #086AD8;
  left: 0;
  right: 0;
  margin: auto;
  transform: rotate(90deg);
  top: -35px;
}
.clients-item .clients-content h3 {
  font-size: 22px;
  margin-bottom: 5px;
}
.clients-item .clients-content span {
  color: "Open Sans", sans-serif;
}

/*================================================
Blog Area CSS
=================================================*/
.single-blog-item {
  margin-bottom: 30px;
}
.single-blog-item .image {
  overflow: hidden;
}
.single-blog-item .image a {
  display: block;
}
.single-blog-item .image a img {
  transition: 0.5s;
}
.single-blog-item .content {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
  padding: 25px;
  transition: 0.5s;
}
.single-blog-item .content span {
  font-size: 14px;
  color: #086AD8;
}
.single-blog-item .content h3 {
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 6px;
}
.single-blog-item .content h3 a {
  display: inline-block;
  line-height: 1.3;
}
.single-blog-item .content .read-more {
  font-weight: 600;
}
.single-blog-item:hover .image a {
  transform: scale(1.09);
}

/*================================================
Banner Area Two CSS
=================================================*/
.main-banner-area-two {
  position: relative;
  height: 100vh;
  z-index: 1;
  background-color: #d0e8fc;
  border-bottom: 1px solid #eeeeee;
  overflow: hidden;
}
.main-banner-area-two .main-banner-content {
  position: relative;
  max-width: 530px;
  margin-left: auto;
  margin-top: -130px;
}
.main-banner-area-two .main-banner-content h1 {
  font-size: 70px;
  color: #212121;
  margin: 5px 0 15px 0;
}
.main-banner-area-two .main-banner-content p {
  color: #212121;
  margin-bottom: 0;
}
.main-banner-area-two .main-banner-content .banner-btn {
  margin-top: 30px;
}
.main-banner-area-two .banner-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.main-banner-area-two .banner-image {
  position: relative;
  margin-top: -40px;
}
.main-banner-area-two .banner-image img {
  position: absolute;
}
.main-banner-area-two .banner-image img:nth-child(1) {
  top: 12px;
  left: 0;
  z-index: 999;
  max-width: 156px;
}
.main-banner-area-two .banner-image img:nth-child(2) {
  left: 16%;
  top: -214px;
  z-index: 999;
}
.main-banner-area-two .banner-image img:nth-child(3) {
  left: 30%;
  z-index: 999;
  top: -246px;
}
.main-banner-area-two .banner-image img:nth-child(4) {
  right: -24px;
  top: -172px;
  max-width: 820px;
}
.main-banner-area-two .banner-image img:nth-child(5) {
  top: 12px;
  right: 0;
  z-index: 999;
  max-width: 156px;
}
.main-banner-area-two .banner-image img:last-child {
  display: none;
}

/*================================================
Banner Area Three CSS
=================================================*/
.main-banner-area-three {
  position: relative;
  height: 690px;
  z-index: 1;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.main-banner-area-three .main-banner-content {
  position: relative;
  max-width: 635px;
  margin-left: auto;
}
.main-banner-area-three .main-banner-content .list {
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.main-banner-area-three .main-banner-content .list li {
  list-style-type: none;
  margin-bottom: 12px;
  font-weight: 600;
}
.main-banner-area-three .main-banner-content .list li:last-child {
  margin-bottom: 0;
}
.main-banner-area-three .main-banner-content .list i {
  color: #086AD8;
  margin-right: 5px;
}
.main-banner-area-three .main-banner-content h1 {
  font-size: 40px;
  color: #212121;
  margin: 10px 0 10px 0;
}
.main-banner-area-three .main-banner-content span {
  font-size: 16px;
  color: #086AD8;
  font-weight: 600;
}
.main-banner-area-three .main-banner-content p {
  color: #212121;
  margin-bottom: 0;
}
.main-banner-area-three .main-banner-content .banner-btn {
  margin-top: 30px;
}
.main-banner-area-three .banner-image {
  position: relative;
  margin-top: -40px;
}
.main-banner-area-three .banner-image img {
  position: absolute;
}
.main-banner-area-three .banner-image img:nth-child(1) {
  top: 12px;
  right: -30px;
  z-index: 999;
}
.main-banner-area-three .banner-image img:nth-child(2) {
  left: 45%;
  top: 100px;
  z-index: 999;
}
.main-banner-area-three .banner-image img:nth-child(3) {
  left: 100px;
  z-index: 999;
  top: 109px;
}
.main-banner-area-three .banner-image img:nth-child(4) {
  left: -112px;
  top: -85px;
}
.main-banner-area-three .banner-image img:nth-child(5) {
  top: -192px;
  left: 15%;
}
.main-banner-area-three .banner-image img:last-child {
  display: none;
}

/*================================================
Banner Area Four CSS
=================================================*/
/*================================================
Banner Area Five CSS
=================================================*/
/*================================================
Banner Area Six CSS
=================================================*/
/*================================================
Work Area CSS
=================================================*/
.work-section.bg-f6f6fe {
  background-color: #f6f6fe;
}

.work-content {
  max-width: 630px;
  margin-left: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
.work-content h3 {
  font-size: 40px;
  margin-bottom: 12px;
}
.work-content span {
  color: #086AD8;
}
.work-content p {
  margin-bottom: 0;
}
.work-content h4 {
  font-size: 22px;
  margin-top: 12px;
  margin-bottom: 8px;
}
.work-content .work-status {
  position: relative;
  margin-top: 20px;
}
.work-content .work-status h3 {
  margin-bottom: 0;
  line-height: 1;
  color: #086AD8;
  font-family: "Open Sans", sans-serif;
  font-size: 50px;
}
.work-content .work-status h3 .sign-icon {
  display: inline-block;
  font-size: 46px;
  position: absolute;
  top: 6px;
}
.work-content .work-status p {
  margin-bottom: 0;
}

.work-image {
  position: relative;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.work-image img {
  display: none;
}
.work-image .video-btn {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 90px;
  background-color: #086AD8;
  border-radius: 50%;
  color: #ffffff;
  position: absolute;
  z-index: 1;
  left: -30px;
  top: 55%;
  transform: translateY(-55%);
  font-size: 35px;
  text-align: center;
}
.work-image .video-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #086AD8;
  animation: ripple 2s linear 1s infinite;
}
.work-image .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #086AD8;
  animation: ripple 2s linear infinite;
}
.work-image .video-btn:hover, .work-image .video-btn:focus {
  background-color: #212121;
  color: #ffffff;
}

/*================================================
Support Area CSS
=================================================*/
.support-content {
  position: relative;
  margin-bottom: 30px;
  padding-left: 67px;
}
.support-content:last-child {
  margin-bottom: 0;
}
.support-content .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 50px;
  color: #086AD8;
}
.support-content h3 {
  font-size: 22px;
  margin-bottom: 6px;
}

/*================================================
Overview Area CSS
=================================================*/
.overview-section {
  background-color: #f0f1fe;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.overview-content {
  text-align: center;
  margin-bottom: 30px;
}
.overview-content .icon {
  background-color: #ffffff;
  border: 1px dashed #086AD8;
  height: 100px;
  width: 100px;
  line-height: 100px;
  border-radius: 50%;
  color: #ffffff;
  position: relative;
  z-index: 1;
  font-size: 30px;
  margin-left: auto;
  margin-right: auto;
}
.overview-content .icon::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #85edfe, #6ee0ff, #60d2ff, #5fc2ff, #6bb1ff);
  border-radius: 50%;
  margin: 10px;
}
.overview-content h3 {
  font-size: 25px;
  margin-top: 25px;
  margin-bottom: 8px;
}
.overview-content p {
  margin-bottom: 0;
}

/*================================================
Fun Facts Area CSS
=================================================*/
.fun-facts-area {
  position: relative;
  z-index: 1;
  background-image: url(assets/img/counter-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.single-fun-fact {
  text-align: center;
  transition: 0.5s;
  position: relative;
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 35px 30px;
  border-radius: 10px;
}
.single-fun-fact h3 {
  line-height: 1;
  font-family: "Open Sans", sans-serif;
  font-size: 50px;
  margin-top: 20px;
  margin-bottom: 0;
}
.single-fun-fact p {
  margin-bottom: 0;
  font-weight: 500;
}
.single-fun-fact .icon {
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  background-color: #e2edfd;
  color: #086AD8;
  font-size: 30px;
  border-radius: 50px;
  transition: 0.5s;
  margin-left: auto;
  margin-right: auto;
}
.single-fun-fact:hover {
  transform: translateY(-10px);
}
.single-fun-fact:hover .icon {
  background-color: #086AD8;
  color: #ffffff;
}

/*================================================
Team Area CSS
=================================================*/
.team-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.single-team-box {
  margin-bottom: 30px;
  transition: 0.5s;
  position: relative;
}
.single-team-box .content {
  background-color: #ffffff;
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
  padding: 20px;
  max-width: 300px;
  margin: -80px auto 0;
  text-align: center;
  position: relative;
  z-index: 1;
  transition: 0.5s;
}
.single-team-box .content h3 {
  font-size: 22px;
  margin-bottom: 6px;
}
.single-team-box .content span {
  font-size: 15px;
  font-weight: 500;
}
.single-team-box .content .social {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: -40px;
  opacity: 0;
  transition: 0.5s;
}
.single-team-box .content .social li {
  list-style-type: none;
  display: inline-block;
  margin-right: 10px;
}
.single-team-box .content .social li:last-child {
  margin-right: 0;
}
.single-team-box .content .social i {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: #086AD8;
  color: #ffffff;
  font-size: 14px;
  border-radius: 30px;
  transition: 0.5s;
}
.single-team-box .content .social i:hover {
  background-color: #10dce8;
}
.single-team-box:hover .content .social {
  margin-top: 15px;
  opacity: 1;
}

.team-item {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}
.team-item .icon {
  margin-bottom: 18px;
  position: relative;
  display: inline-block;
  z-index: 1;
  width: 80%;
  height: 80%;
  line-height: 80px;
  border: 1px solid #dcffd0;
  border-radius: 50%;
  color: #086AD8;
  transition: var(--transition);
}
.team-item .icon::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #daffce;
  z-index: -1;
  border-radius: 50%;
  margin: 5px;
  transition: var(--transition);
}
.team-item .image {
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  border-radius: 50%;
  width: 50%;
  margin-left: 40px;
  border-radius: 50%;
  border: 1px solid #086AD8;
}
.team-item .image .social {
  padding-left: 0;
  list-style-type: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
.team-item .image .social li {
  display: inline-block;
  transform: translateY(30px);
  transition: all 0.3s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
  margin-left: 1px;
  margin-right: 1px;
}
.team-item .image .social li a {
  display: block;
  width: 40px;
  height: 40px;
  background-color: #086AD8;
  text-align: center;
  position: relative;
  font-size: 20px;
  color: #ffffff;
  border-radius: 30px;
  transition: 0.5s;
}
.team-item .image .social li a i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}
.team-item .image .social li a:hover {
  color: #ffffff;
  background-color: #10dce8;
}
.team-item .image .social li:nth-child(1) {
  transition-delay: 0.2s;
}
.team-item .image .social li:nth-child(2) {
  transition-delay: 0.3s;
}
.team-item .image .social li:nth-child(3) {
  transition-delay: 0.4s;
}
.team-item .image .social li:nth-child(4) {
  transition-delay: 0.5s;
}
.team-item .content {
  background-color: #ffffff;
}
.team-item .content h3 {
  margin-bottom: 0;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.team-item .content span {
  display: block;
  color: #086AD8;
  margin-top: 7px;
}
.team-item:hover .image .social li {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

/*================================================
Skill Area CSS
=================================================*/
.skills-section {
  overflow: hidden;
}

.skills-image {
  width: 100%;
  height: 600px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.skills-image img {
  display: none;
}

.skills-area {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  padding: 50px;
  margin-left: -200px;
  margin-right: 200px;
}
.skills-area .skills-content h3 {
  font-size: 40px;
  margin-bottom: 16px;
  position: relative;
}
.skills-area .skills-content h3::before {
  content: "";
  position: absolute;
}
.skills-area .skills-content span {
  color: #086AD8;
}
.skills-area .skills-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 16px;
}
.skills-area .skills-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
.skills-area .skill-bar {
  margin-top: 30px;
  font-family: "Open Sans", sans-serif;
}
.skills-area .skill-bar .progress-title-holder {
  position: relative;
  margin-bottom: 10px;
}
.skills-area .skill-bar .progress-title {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.skills-area .skill-bar .progress-number-wrapper {
  width: 100%;
  z-index: 10;
  line-height: 24px;
  height: 24px;
  letter-spacing: 0px;
  text-transform: none;
  color: #ffffff;
  font-weight: 600;
  font-style: normal;
  font-size: 11px;
}
.skills-area .skill-bar .progress-number-mark {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  color: #212121;
  font-size: 16px;
  font-weight: 500;
}
.skills-area .skill-bar .progress-content-outter {
  height: 6px;
  background-color: #e4e4e4;
  border-radius: 4px;
}
.skills-area .skill-bar .progress-content {
  height: 6px;
  background: linear-gradient(to right, #bcf2f5, #b0eaf7, #a9e0f9, #a7d6f9, #abcbf6);
  border-radius: 4px;
  width: 0%;
}

/*================================================
Audience Area CSS
=================================================*/
.audience-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.audience-content h3 {
  font-size: 40px;
  margin-bottom: 14px;
  position: relative;
}
.audience-content h3 span {
  color: #086AD8;
}
.audience-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}
.audience-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
.audience-content .audience-btn {
  margin-top: 25px;
}

/*================================================
Solutions Area CSS
=================================================*/
.solutions-content-area {
  max-width: 635px;
  margin-left: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
.solutions-content-area .solutions-content h3 {
  font-size: 40px;
  margin-bottom: 12px;
  position: relative;
}
.solutions-content-area .solutions-content h3 span {
  color: #086AD8;
}
.solutions-content-area .solutions-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}
.solutions-content-area .solutions-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
.solutions-content-area .solutions-content .audience-btn {
  margin-top: 25px;
}
.solutions-content-area .solutions-details {
  margin-top: 30px;
  position: relative;
  padding-left: 90px;
}
.solutions-content-area .solutions-details .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 70px;
  width: 70px;
  line-height: 70px;
  background-color: #f2e4fc;
  color: #b45df5;
  text-align: center;
  font-size: 32px;
  border-radius: 50px;
}
.solutions-content-area .solutions-details .icon.bg-d3fbf9 {
  background-color: #d3fbf9;
  color: #4ee8df;
}
.solutions-content-area .solutions-details .icon.bg-fce8c9 {
  background-color: #fce8c9;
  color: #f7b042;
}
.solutions-content-area .solutions-details .icon.bg-d5e6fe {
  background-color: #d5e6fe;
  color: #7fb4ff;
}
.solutions-content-area .solutions-details h3 {
  font-size: 22px;
  margin-bottom: 8px;
}
.solutions-content-area .solutions-details p {
  margin-bottom: 0;
  font-size: 14px;
}

.solutions-image {
  height: 100%;
  background-image: url(assets/img/feature-solution.jpg);
  background-size: 70%;
  background-position: center center;
  background-repeat: no-repeat;
  background-margin-top: 60px;
}
.solutions-image img {
  display: none;
}

/*================================================
Design Area CSS
=================================================*/
.design-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.design-content h3 {
  font-size: 40px;
  margin-bottom: 16px;
}
.design-content span {
  color: #086AD8;
}
.design-content p {
  margin-bottom: 0;
}
.design-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 16px;
}
.design-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
.design-content .design-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: -10px;
  margin-right: -10px;
}
.design-content .design-list li {
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 30px;
  color: #212121;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  font-size: 16px;
}
.design-content .design-list i {
  color: #086AD8;
  display: inline-block;
  height: 25px;
  width: 25px;
  line-height: 25px;
  background-color: #d7f2fc;
  text-align: center;
  margin-right: 5px;
}

/*================================================
Counter Area CSS
=================================================*/
.counter-section {
  background-color: #eceefe;
}

.single-counter {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 35px 30px;
  border-radius: 10px;
  transition: 0.5s;
  z-index: 1;
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-counter h3 {
  line-height: 1;
  transition: 0.5s;
  font-family: "Open Sans", sans-serif;
  font-size: 50px;
  margin-top: 18px;
  margin-bottom: 3px;
}
.single-counter p {
  margin-bottom: 0;
  transition: 0.5s;
  font-weight: 500;
}
.single-counter .icon {
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  background-color: #e2edfd;
  color: #086AD8;
  font-size: 30px;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
}
.single-counter::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 0%;
  background: #086AD8;
  transition: 0.5s;
  border-radius: 10px;
}
.single-counter:hover::before {
  height: 100%;
}
.single-counter:hover h3 {
  color: #ffffff;
}
.single-counter:hover p {
  color: #ffffff;
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-section {
  background-color: #faf9ff;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.single-pricing {
  background-color: #c2f2fd;
  padding: 50px 90px;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 50px 4px 50px 4px;
}
.single-pricing .pricing-header h3 {
  font-size: 25px;
  color: #212121;
  margin-bottom: 0;
}
.single-pricing .pricing-header p {
  color: #212121;
  font-weight: 500;
}
.single-pricing .price {
  display: inline-block;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background-color: #ffffff;
  border-radius: 50px;
  font-size: 25px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 16px;
}
.single-pricing .price sup {
  font-weight: 400;
}
.single-pricing .price sub {
  font-weight: 400;
}
.single-pricing .pricing-list {
  padding-left: 0;
  margin-bottom: 0;
  text-align: left;
}
.single-pricing .pricing-list li {
  list-style-type: none;
  padding-bottom: 12px;
  font-size: 16px;
  color: #212121;
  font-weight: 500;
}
.single-pricing .pricing-list li:last-child {
  padding-bottom: 0;
}
.single-pricing .pricing-list i {
  color: #086AD8;
  margin-right: 5px;
}
.single-pricing .price-btn {
  margin-top: 25px;
}
.single-pricing .price-btn .default-btn {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
  padding: 12px 30px;
  display: inline-block;
  background-color: #ffffff;
  color: #212121;
  font-weight: 600;
  border-radius: 30px;
}
.single-pricing:hover .price-btn .default-btn {
  background-color: #086AD8;
  color: #ffffff;
}

/*================================================
Protfolio Area CSS
=================================================*/
.protfolio-section.bg-color {
  background-color: #f2f0fe;
}

.single-protfolio-box {
  margin-bottom: 30px;
  transition: 0.5s;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-protfolio-box .image a {
  display: block;
}
.single-protfolio-box .content {
  padding: 30px;
  position: relative;
}
.single-protfolio-box .content h3 {
  font-size: 22px;
  margin-bottom: 6px;
  transition: 0.5s;
}
.single-protfolio-box .content span {
  transition: 0.5s;
}
.single-protfolio-box .content .link-btn {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.single-protfolio-box:hover .content {
  background-color: #086AD8;
}
.single-protfolio-box:hover h3 {
  color: #ffffff;
}
.single-protfolio-box:hover h3 a {
  color: #ffffff;
}
.single-protfolio-box:hover span {
  color: #ffffff;
}

/*================================================
Process Area CSS
=================================================*/
.process-content {
  background-color: #f5f6fe;
  padding: 30px;
  margin-bottom: 30px;
}
.process-content .number {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #086AD8;
  color: #ffffff;
  border-radius: 30px;
  font-weight: 600;
}
.process-content h3 {
  font-size: 25px;
  margin-bottom: 0;
  margin-top: 20px;
}
.process-content .content {
  margin-top: 35px;
}
.process-content .content h4 {
  font-size: 22px;
  margin-bottom: 5px;
  margin-top: 0;
}
.process-content .image {
  margin-top: 20px;
}

/*================================================
About Area CSS
=================================================*/
.tab a {
  text-decoration: none;
  transition: 0.5s;
  color: #212121;
}
.tab a:hover, .tab a:focus {
  color: #086AD8;
  text-decoration: none;
}
.tab .tabs_item {
  display: none;
}
.tab .tabs_item:first-child {
  display: block;
}

.about-tab {
  margin-left: 50px;
}
.about-tab h2 {
  font-size: 40px;
  margin-bottom: 14px;
}
.about-tab .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}
.about-tab .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
.about-tab .about-list-tab .tabs {
  border-bottom: 1px solid #eeeeee;
  padding-left: 0;
  margin-bottom: 30px;
  margin-top: 20px;
}
.about-tab .about-list-tab .tabs li {
  padding: 0;
  margin-right: 30px;
  padding-bottom: 10px;
  display: inline-block;
}
.about-tab .about-list-tab .tabs li.current {
  border-bottom: 2px solid #086AD8;
}
.about-tab .about-list-tab .tabs li.current a {
  color: #086AD8;
}
.about-tab .about-list-tab .tabs li a {
  position: relative;
  display: block;
  font-weight: 600;
}
.about-tab .about-list-tab .tab_content .tabs_item .text h3 {
  font-size: 22px;
  margin-bottom: 0;
}
.about-tab .about-list-tab .tab_content .tabs_item .list {
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.about-tab .about-list-tab .tab_content .tabs_item .list li {
  list-style-type: none;
  margin-bottom: 12px;
  font-weight: 600;
}
.about-tab .about-list-tab .tab_content .tabs_item .list li:last-child {
  margin-bottom: 0;
}
.about-tab .about-list-tab .tab_content .tabs_item .list i {
  color: #086AD8;
  margin-right: 5px;
}
.about-tab .about-list-tab .tab_content .tabs_item p {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0;
}
.about-tab .about-list-tab .tab_content .tabs_item .default-btn {
  margin-top: 30px;
}

.about-image {
  height: 100%;
  background-size: 75%;
  background-position: center center;
  background-repeat: no-repeat;
}
.about-image img {
  display: none;
}

/*================================================
Review Area CSS
=================================================*/
.review-content h3 {
  font-size: 40px;
  margin-bottom: 14px;
  position: relative;
}
.review-content h3 span {
  color: #086AD8;
}
.review-content p {
  margin-bottom: 0;
}
.review-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}
.review-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}

.review-text {
  position: relative;
  margin-top: 30px;
  padding-left: 35px;
}
.review-text .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  color: #086AD8;
}
.review-text h3 {
  font-size: 22px;
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-inner-area {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.subscribe-inner-area::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  background-color: #086AD8;
  opacity: 0.8;
}

.subscribe-inner-text {
  text-align: center;
}
.subscribe-inner-text h2 {
  color: #ffffff;
  font-size: 40px;
  max-width: 700px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
.subscribe-inner-text p {
  color: #ffffff;
}
.subscribe-inner-text .newsletter-form {
  max-width: 550px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
}
.subscribe-inner-text .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border: none;
  height: 65px;
  border-radius: 70px;
  outline: 0;
  color: #212121;
  padding-left: 25px;
  padding-top: 5px;
}
.subscribe-inner-text .newsletter-form button {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #212121;
  color: #ffffff;
  border: none;
  height: 55px;
  padding: 0 30px;
  border-radius: 70px;
  transition: 0.5s;
  line-height: 55px;
  font-size: 15px;
  font-weight: 700;
}
.subscribe-inner-text .newsletter-form button:hover {
  background-color: #086AD8;
  color: #ffffff;
}
.subscribe-inner-text .newsletter-form #validator-newsletter {
  color: red;
  margin-top: 15px;
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion .card {
  display: block;
  box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  background: #ffffff;
  margin-bottom: 15px;
  border-radius: 0;
  border: none;
}
.faq-accordion .card:last-child {
  margin-bottom: 0;
}
.faq-accordion .card .card-header {
  border-radius: 0 !important;
  padding: 0;
  border-bottom: none;
  background-color: #ffffff;
}
.faq-accordion .card .card-header a {
  padding: 12px 35px 10px 15px;
  color: #212121;
  text-decoration: none;
  position: relative;
  display: block;
  border-bottom: 1px solid #eeeeee;
  font-size: 16px;
  font-weight: 600;
}
.faq-accordion .card .card-header a i {
  position: absolute;
  right: 15px;
  top: 50%;
  font-size: 20px;
  transform: rotate(180deg) translateY(50%);
}
.faq-accordion .card .card-header a.collapsed {
  border-bottom-color: transparent;
}
.faq-accordion .card .card-header a.collapsed i {
  transform: rotate(0) translateY(-50%);
}
.faq-accordion .card .collapse .card-body {
  position: relative;
  padding: 20px;
}

/*================================================
Learn Area CSS
=================================================*/
.learn-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
}
.learn-content p {
  margin-bottom: 0;
}
.learn-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}
.learn-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
.learn-content .learn-list {
  padding-left: 0;
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
}
.learn-content .learn-list li {
  list-style-type: none;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
}
.learn-content .learn-list li:last-child {
  margin-bottom: 0;
}
.learn-content .learn-list i {
  color: #086AD8;
  margin-right: 3px;
  font-size: 20px;
  position: relative;
  top: 3px;
}

.learn-inner-content {
  background-color: #ffffff;
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
  padding: 30px;
  text-align: center;
  position: relative;
  z-index: 1;
  transition: 0.5s;
  border-radius: 10px;
}
.learn-inner-content.mb-30 {
  margin-bottom: 30px;
}
.learn-inner-content .icon {
  height: 70px;
  width: 70px;
  line-height: 70px;
  background-color: #086AD8;
  color: #ffffff;
  font-size: 35px;
  border-radius: 50px;
  transition: 0.5s;
  margin-left: auto;
  margin-right: auto;
}
.learn-inner-content .icon.bg-ba60fc {
  background-color: #ba60fc;
}
.learn-inner-content .icon.bg-04cfc4 {
  background-color: #04cfc4;
}
.learn-inner-content .icon.bg-f9b854 {
  background-color: #f9b854;
}
.learn-inner-content h3 {
  font-size: 22px;
  margin: 25px 0 8px 0;
  transition: 0.5s;
}
.learn-inner-content p {
  margin-bottom: 0;
  transition: 0.5s;
}
.learn-inner-content .read-btn {
  display: inline-block;
  margin-top: 10px;
  font-weight: 600;
  transition: 0.5s;
}
.learn-inner-content::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 0%;
  background: #086AD8;
  transition: 0.5s;
  border-radius: 12px;
}
.learn-inner-content:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.learn-inner-content:hover::before {
  height: 100%;
}
.learn-inner-content:hover h3 {
  color: #ffffff;
}
.learn-inner-content:hover p {
  color: #ffffff;
}
.learn-inner-content:hover .read-btn {
  color: #ffffff;
}
.learn-inner-content:hover .icon {
  background-color: #ffffff;
  color: #086AD8;
}

/*================================================
App Area CSS
=================================================*/
.app-content {
  padding-left: 15px;
}
.app-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
}
.app-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}
.app-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}

.app-inner-text {
  position: relative;
  margin-top: 30px;
  padding-left: 85px;
}
.app-inner-text .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 70px;
  width: 70px;
  line-height: 70px;
  background-color: #086AD8;
  color: #ffffff;
  text-align: center;
  font-size: 35px;
  border-radius: 50px;
}
.app-inner-text h3 {
  font-size: 22px;
  margin-bottom: 8px;
}
.app-inner-text p {
  margin-bottom: 0;
}

/*================================================
Data Area CSS
=================================================*/
.data-section {
  background-color: #f8fcfe;
}

.data-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
}
.data-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}
.data-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}

.data-inner-content {
  background-color: #ffffff;
  padding: 30px;
}
.data-inner-content i {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 20px;
  background-color: #086AD8;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
}
.data-inner-content h3 {
  font-size: 22px;
  margin: 15px 0 2px 0;
}
.data-inner-content.bg-facf34 i {
  background-color: #facf34;
}

/*================================================
Customer Area CSS
=================================================*/
.customer-section {
  background: linear-gradient(to right, #d5f8fe, #9be3ff, #71c8ff, #74a8ff, #9d7df8);
}

.customer-content h3 {
  font-size: 40px;
  margin-bottom: 14px;
}
.customer-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}
.customer-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
.customer-content p {
  color: #212121;
}
.customer-content .customer-btn {
  margin-top: 30px;
}

.customer-image {
  text-align: center;
}

/*================================================
More Features Area CSS
=================================================*/
.more-features-content {
  text-align: center;
  margin-bottom: 30px;
  background-color: #f1eff8;
  border-radius: 5px;
  padding: 40px 20px;
  transition: 0.5s;
}
.more-features-content .icon {
  height: 60px;
  width: 60px;
  line-height: 60px;
  background-color: #ffffff;
  color: #086AD8;
  font-size: 30px;
  border-radius: 50px;
  border: 1px dashed #086AD8;
  margin-left: auto;
  margin-right: auto;
}
.more-features-content h3 {
  font-size: 22px;
  transition: 0.5s;
  margin-bottom: 6px;
  margin-top: 25px;
}
.more-features-content p {
  transition: 0.5s;
}
.more-features-content.bg-f0fffc {
  background-color: #f0fffc;
}
.more-features-content.bg-f0fffc .icon {
  color: #22b396;
  border: 1px dashed #22b396;
}
.more-features-content.bg-fceee2 {
  background-color: #fceee2;
}
.more-features-content.bg-fceee2 .icon {
  background-color: #ffffff;
  color: #f1760c;
  border: 1px dashed #f1760c;
}
.more-features-content.bg-fde2ee {
  background-color: #fde2ee;
}
.more-features-content.bg-fde2ee .icon {
  background-color: #ffffff;
  color: #f23e8a;
  border: 1px dashed #f23e8a;
}
.more-features-content:hover {
  background-color: #086AD8;
}
.more-features-content:hover h3 {
  color: #ffffff;
}
.more-features-content:hover p {
  color: #ffffff;
}

/*================================================
Digital Area CSS
=================================================*/
.digital-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
}
.digital-content span {
  color: #086AD8;
}
.digital-content p {
  margin-bottom: 0;
}
.digital-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}
.digital-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
.digital-content .digital-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: -10px;
  margin-right: -10px;
}
.digital-content .digital-list li {
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 30px;
  color: #212121;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  font-weight: 600;
}
.digital-content .digital-list i {
  color: #086AD8;
  display: inline-block;
  height: 25px;
  width: 25px;
  line-height: 25px;
  background-color: #d7f2fc;
  text-align: center;
  margin-right: 5px;
}
.digital-content .digital-btn {
  margin-top: 30px;
}

/*================================================
Experience Area CSS
=================================================*/
.experience-inner-area {
  max-width: 630px;
  margin-left: auto;
}
.experience-inner-area .experience-content h2 {
  font-size: 40px;
  margin-bottom: 15px;
}
.experience-inner-area .experience-content h2 span {
  color: #086AD8;
}
.experience-inner-area .experience-content p {
  margin-bottom: 0;
}
.experience-inner-area .experience-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}
.experience-inner-area .experience-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
.experience-inner-area .experience-inner-content {
  position: relative;
  margin-top: 30px;
  padding-left: 60px;
}
.experience-inner-area .experience-inner-content .icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: #086AD8;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  border-radius: 2px;
}
.experience-inner-area .experience-inner-content h3 {
  font-size: 22px;
  margin-bottom: 8px;
}

/*================================================
Contact Area CSS
=================================================*/
.contact-text {
  margin-bottom: 30px;
}
.contact-text h2 {
  margin-bottom: 10px;
  font-size: 40px;
}
.contact-text h2 span {
  color: #086AD8;
}

.contact-form {
  background-color: #ffffff;
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
  padding: 35px;
}
.contact-form form .form-group {
  margin-bottom: 15px;
}
.contact-form form .form-control {
  height: 48px;
  padding: 0 15px;
  line-height: initial;
  color: #212121;
  background-color: #f8f6f6;
  border: 1px solid #f8f6f6;
  border-radius: 5px;
  transition: 0.5s;
  box-shadow: unset !important;
  font-size: 14px;
  font-weight: 400;
}
.contact-form form .form-control:focus {
  border-color: #086AD8;
}
.contact-form form .form-control::placeholder {
  color: #999999;
}
.contact-form form textarea.form-control {
  height: auto !important;
  padding-top: 15px;
}
.contact-form .send-btn {
  margin-top: 10px;
  transition: 0.5s;
}
.contact-form .send-btn .default-btn {
  border: none;
  display: inline-block;
  width: 100%;
}
.contact-form .with-errors ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 5px;
  margin-bottom: 0;
}
.contact-form .with-errors ul li {
  color: red;
}
.contact-form #msgSubmit {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 500;
}
.contact-form #msgSubmit.text-danger {
  margin-top: 20px !important;
  font-family: "Open Sans", sans-serif;
}

/*================================================
Tech Area CSS
=================================================*/
.tech-section {
  background-color: #f6f6fe;
}

.tech-content h2 {
  font-size: 40px;
  margin-bottom: 15px;
}
.tech-content h2 span {
  color: #086AD8;
}
.tech-content p {
  margin-bottom: 0;
}
.tech-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}
.tech-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
.tech-content .tech-btn {
  margin-top: 30px;
}

/*================================================
Agency Services Area CSS
=================================================*/
.agency-services-section {
  background-color: #f6f6fe;
  position: relative;
}

.single-agency-services {
  margin-bottom: 30px;
  position: relative;
}
.single-agency-services .image a {
  display: block;
}
.single-agency-services .content {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  padding: 30px;
  text-align: center;
  position: relative;
  z-index: 1;
  max-width: 320px;
  margin: -50px auto 0px;
}
.single-agency-services .content h3 {
  font-size: 22px;
  margin-bottom: 8px;
  transition: 0.5s;
}
.single-agency-services .content span {
  transition: 0.5s;
}
.single-agency-services .content::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 0%;
  background: #086AD8;
  transition: 0.5s;
}
.single-agency-services .content .link-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  z-index: 2;
}
.single-agency-services:hover .content::before {
  height: 100%;
}
.single-agency-services:hover .content h3 {
  color: #ffffff;
}
.single-agency-services:hover .content span {
  color: #ffffff;
}

/*================================================
Tab Area CSS
=================================================*/
.tab .tabs_item {
  display: none;
}
.tab .tabs_item:first-child {
  display: block;
}

.schedule-list-tab .tabs {
  padding-left: 0;
  margin-bottom: 65px;
  text-align: center;
}
.schedule-list-tab .tabs li {
  text-align: center;
  display: inline-block;
  list-style: none;
  background-color: #ffffff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  margin-right: 15px;
  border-radius: 10px;
  transition: 0.5s;
}
.schedule-list-tab .tabs li:last-child {
  margin-right: 0;
}
.schedule-list-tab .tabs li a {
  display: block;
  color: #212121;
  padding: 20px 30px;
  font-size: 16px;
  font-weight: 600;
}
.schedule-list-tab .tabs li a span {
  display: block;
  margin-top: 0;
}
.schedule-list-tab .tabs li a span i {
  display: block;
  font-size: 35px;
  margin-bottom: 8px;
  color: #086AD8;
  transition: 0.5s;
}
.schedule-list-tab .tabs li.current, .schedule-list-tab .tabs li:hover {
  background-color: #086AD8;
}
.schedule-list-tab .tabs li.current a, .schedule-list-tab .tabs li:hover a {
  color: #ffffff;
}
.schedule-list-tab .tabs li.current a span i, .schedule-list-tab .tabs li:hover a span i {
  color: #ffffff;
}
.schedule-list-tab .tab_content .tabs_item .tab-content h4 {
  font-size: 40px;
  margin-bottom: 8px;
}
.schedule-list-tab .tab_content .tabs_item .tab-content p {
  margin-bottom: 0;
}
.schedule-list-tab .tab_content .tabs_item .tab-content .tab-inner-content {
  margin-top: 25px;
  position: relative;
  padding-left: 55px;
  transition: 0.5s;
}
.schedule-list-tab .tab_content .tabs_item .tab-content .tab-inner-content .icon {
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: #086AD8;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  border-radius: 30px;
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.5s;
}
.schedule-list-tab .tab_content .tabs_item .tab-content .tab-inner-content h3 {
  font-size: 22px;
  margin-bottom: 6px;
}
.schedule-list-tab .tab_content .tabs_item .tab-content .tab-inner-content p {
  margin-bottom: 0;
}
.schedule-list-tab .tab_content .tabs_item .tab-content .tab-inner-content:hover .icon {
  background-color: #10dce8;
  color: #ffffff;
}

.projects-wrap-slides.owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
}
.projects-wrap-slides.owl-theme .owl-nav [class*=owl-] {
  display: block;
  margin: 0;
  text-align: center;
  height: 50px;
  line-height: 50px;
  width: 50px;
  background: var(--whiteColor);
  color: var(--mainColor);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0;
  transition: var(--transition);
  position: relative;
  z-index: 1;
  font-weight: 600;
  font-size: 18.5px;
  font-family: var(--optionalFontFamily);
}
.projects-wrap-slides.owl-theme .owl-nav [class*=owl-] i {
  position: relative;
  top: -1px;
}
.projects-wrap-slides.owl-theme .owl-nav [class*=owl-] i::before {
  font-size: 16.5px;
}
.projects-wrap-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--whiteColor);
  background-color: var(--mediumPurpleColor);
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  position: relative;
  z-index: 1;
  background: transparent url(assets/img_tfe/page-title-bg.jpg) right top no-repeat;
  text-align: center;
  padding-top: 190px;
}

.page-title-content .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  margin: 20px auto 0;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.page-title-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
.page-title-content h2 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 700;
}
.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  font-family: var(--optionalFontFamily);
  margin-top: 15px;
  margin-bottom: 0;
}
.page-title-content ul li {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
  font-weight: 600;
  font-size: 15px;
}
.page-title-content ul li a {
  color: var(--mainColor);
}
.page-title-content ul li a:hover {
  color: var(--mainColor);
}
.page-title-content ul li::before {
  content: "\f054";
  position: absolute;
  right: -15px;
  top: 6px;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  font-size: 10px;
}
.page-title-content ul li:last-child::before {
  display: none;
}

.navbar-brand {
  margin-top: 7px;
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
  margin-top: 20px;
  text-align: center;
}
.pagination-area .page-numbers {
  width: 45px;
  height: 45px;
  margin: 0 5px;
  display: inline-block;
  background-color: #ffffff;
  line-height: 45px;
  color: #212121;
  box-shadow: 0 2px 10px 0 #d8dde6;
  font-size: 18px;
  font-weight: 700;
}
.pagination-area .page-numbers.current, .pagination-area .page-numbers:hover, .pagination-area .page-numbers:focus {
  background: #086AD8;
  color: #ffffff;
  box-shadow: 0 2px 10px 0 #d8dde6;
}

/*================================================
Login In CSS
=================================================*/
.login-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.login-form {
  background: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 5px;
  max-width: 600px;
  margin: auto;
}
.login-form .login-title {
  text-align: center;
  margin-bottom: 30px;
}
.login-form .login-title h3 {
  font-size: 30px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 8px;
}
.login-form .login-title p {
  margin-bottom: 30px;
}
.login-form form .form-control {
  height: 50px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  color: #212121;
  padding-left: 20px;
  background: transparent;
}
.login-form form .form-control:focus {
  border-color: #086AD8;
  outline: 0;
  box-shadow: none;
}
.login-form form .form-control::placeholder {
  color: #666666;
}
.login-form form .form-check {
  margin-top: 18px;
}
.login-form form .form-check .form-check-label {
  color: #666666;
  position: relative;
  top: -2px;
  z-index: 1;
}
.login-form form .form-check-input {
  margin-top: 3px;
}
.login-form form .send-btn {
  margin-top: 20px;
}
.login-form form .send-btn .default-btn-one {
  display: inline-block;
  padding: 12px 30px;
  background: #086AD8;
  border: 1px solid #086AD8;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  transition: 0.6s;
}
.login-form form .send-btn .default-btn-one:hover {
  text-decoration: none;
  background-color: #ffffff;
  color: #086AD8;
}
.login-form form span {
  display: block;
  color: #777777;
}
.login-form form span a {
  color: #086AD8;
}
.login-form .forgot-password {
  margin-bottom: 0;
  margin-top: -25px;
}
.login-form .forgot-password a {
  color: #777777;
}
.login-form .forgot-password a:hover {
  color: #086AD8;
}

/*================================================
Sign Up CSS
=================================================*/
.signup-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.signup-form {
  background: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 5px;
  max-width: 600px;
  margin: auto;
}
.signup-form h3 {
  font-size: 30px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
  text-align: center;
}
.signup-form form .form-control {
  height: 50px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  color: #212121;
  padding-left: 20px;
  background: transparent;
}
.signup-form form .form-control:focus {
  border-color: #086AD8;
  outline: 0;
  box-shadow: none;
}
.signup-form form .form-control::placeholder {
  color: #666666;
}
.signup-form form .form-check {
  margin-top: 18px;
}
.signup-form form .form-check .form-check-label {
  color: #666666;
  position: relative;
  top: -2px;
}
.signup-form form .send-btn {
  margin-top: 20px;
}
.signup-form form .send-btn .default-btn-one {
  display: inline-block;
  padding: 12px 30px;
  background: #086AD8;
  border: 1px solid #086AD8;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  transition: 0.6s;
}
.signup-form form .send-btn .default-btn-one:hover {
  text-decoration: none;
  background-color: #ffffff;
  color: #086AD8;
}
.signup-form form span {
  display: block;
  color: #666666;
}
.signup-form form span a {
  color: #086AD8;
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
  height: 100vh;
}

.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
}
.error-content h3 {
  font-size: 50px;
  font-family: "Open Sans", sans-serif;
  margin-top: 45px;
  margin-bottom: 15px;
}
.error-content p {
  max-width: 520px;
  margin: 0 auto 20px;
}

/*================================================
Privacy Section CSS
=================================================*/
.conditions-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.privacy-policy {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.single-privacy h3 {
  font-size: 25px;
  margin-bottom: 10px;
}
.single-privacy h3:not(:first-child) {
  margin-top: 30px;
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
  position: relative;
  z-index: 1;
  height: 100vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.coming-soon-area::before {
  z-index: -1;
  background: #000000;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.8;
}
.coming-soon-area .social-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  bottom: 30px;
}
.coming-soon-area .social-list li {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
}
.coming-soon-area .social-list li.list-heading {
  display: block;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}
.coming-soon-area .social-list li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: #086AD8;
  border: 1px solid #086AD8;
  color: #ffffff;
  border-radius: 50%;
  margin: 0 2px;
  display: inline-block;
}
.coming-soon-area .social-list li a:hover {
  background-color: transparent;
  color: #086AD8;
  transition: 0.6s;
  -webkit-transition: 0.5s;
}

.coming-soon-content {
  text-align: center;
  max-width: 820px;
  margin: -80px auto 0;
}
.coming-soon-content h1 {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 46px;
  font-weight: 700;
}
.coming-soon-content p {
  color: #ffffff;
  font-size: 14px;
  margin: 0 auto;
  max-width: 620px;
  line-height: 30px;
}
.coming-soon-content form {
  position: relative;
  margin: 35px auto 55px;
  max-width: 520px;
}
.coming-soon-content form .email-input {
  display: block;
  width: 100%;
  height: 56px;
  border: none;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 5px rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  padding: 15px 25px;
  outline: 0 !important;
  background: #F1F2F3;
}
.coming-soon-content form .submit-btn {
  position: absolute;
  right: 3px;
  top: 3px;
  height: 50px;
  background: #086AD8;
  color: #ffffff;
  border: none;
  border-radius: 40px;
  width: 130px;
  outline: 0 !important;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.coming-soon-content form .submit-btn:hover, .coming-soon-content form .submit-btn:focus {
  background-color: #086AD8;
}
.coming-soon-content #timer div {
  background: #ffffff;
  display: inline-block;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  color: #086AD8;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5);
  margin: 0 10px;
  padding-top: 18px;
  font-size: 40px;
  font-weight: 700;
}
.coming-soon-content #timer div span {
  display: block;
  margin-top: -4px;
  color: #777777;
  font-size: 14px;
  font-weight: 600;
}

/*================================================
Contact Section CSS
=================================================*/
.single-contact-box {
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  margin-bottom: 30px;
}
.single-contact-box i {
  line-height: 50px;
  width: 50px;
  height: 50px;
  border: 1px dashed #086AD8;
  border-radius: 50%;
  font-size: 20px;
  display: inline-block;
  color: #086AD8;
  transition: all 0.5s;
  margin-bottom: 20px;
}
.single-contact-box:hover i {
  background-color: #086AD8;
  color: #ffffff;
}
.single-contact-box .content-title h3 {
  font-size: 25px;
  margin-bottom: 10px;
}
.single-contact-box .content-title a {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}
.single-contact-box .content-title a:hover {
  color: #086AD8;
}

/*================================================
Services Details CSS
=================================================*/
.services-details-overview {
  margin-bottom: 60px;
  margin-top: 60px;
}
.services-details-overview:last-child {
  margin-bottom: 0;
}
.services-details-overview .services-details-desc h3 {
  margin-bottom: 15px;
  font-size: 24px;
}
.services-details-overview .services-details-desc .features-text {
  margin-top: 25px;
}
.services-details-overview .services-details-desc .features-text h4 {
  margin-bottom: 10px;
  font-size: 18px;
}
.services-details-overview .services-details-desc .services-details-accordion {
  margin-top: 25px;
}

.services-details-video {
  position: relative;
}
.services-details-video .details-video {
  text-align: center;
  position: absolute;
  left: 0px;
  right: 0;
  top: 40%;
  transform: translateY(-25%);
  margin: auto;
}
.services-details-video .details-video .video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: #ffffff;
  border-radius: 50%;
  color: #086AD8;
  position: relative;
  z-index: 1;
  font-size: 35px;
}
.services-details-video .details-video .video-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  animation: ripple 2s linear 1s infinite;
}
.services-details-video .details-video .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  animation: ripple 2s linear infinite;
}
.services-details-video .details-video .video-btn:hover, .services-details-video .details-video .video-btn .video-content .video-btn:focus {
  background-color: #086AD8;
  color: #ffffff;
}
.services-details-video .text {
  margin-top: 30px;
}
.services-details-video .text h3 {
  font-size: 24px;
  margin-top: 25px;
  margin-bottom: 10px;
}

/*================================================
Projects Details Area CSS
=================================================*/
.project-details-image {
  margin-bottom: 30px;
}

.projects-details-desc {
  margin-top: 5px;
}
.projects-details-desc h3 {
  margin-bottom: 13px;
  font-size: 24px;
}
.projects-details-desc .features-text {
  margin-top: 20px;
  margin-bottom: 25px;
}
.projects-details-desc .features-text h4 {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
}
.projects-details-desc .features-text h4 i {
  font-size: 16px;
  margin-right: 4px;
  color: #086AD8;
}
.projects-details-desc .project-details-info {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 35px;
}
.projects-details-desc .project-details-info .single-info-box {
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 15px;
  padding-right: 15px;
}
.projects-details-desc .project-details-info .single-info-box h4 {
  margin-bottom: 10px;
  font-size: 18px;
}
.projects-details-desc .project-details-info .single-info-box span {
  display: block;
  color: #10dce8;
  font-size: 15px;
}
.projects-details-desc .project-details-info .single-info-box .social {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.projects-details-desc .project-details-info .single-info-box .social li {
  display: inline-block;
  margin-right: 8px;
}
.projects-details-desc .project-details-info .single-info-box .social a {
  color: #10dce8;
  display: inline-block;
}
.projects-details-desc .project-details-info .single-info-box .social a:hover {
  color: #086AD8;
  transform: translateY(-5px);
}

/*================================================
Sidebar Widget Area CSS
=================================================*/
.widget-area .widget {
  margin-top: 35px;
}
.widget-area .widget:first-child {
  margin-top: 0;
}
.widget-area .widget .widget-title {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 25px;
  text-transform: capitalize;
  position: relative;
  font-size: 21px;
}
.widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  background: #086AD8;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}
.widget-area .widget_search {
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  background-color: #ffffff;
  padding: 15px;
}
.widget-area .widget_search form {
  position: relative;
}
.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}
.widget-area .widget_search form .search-field {
  background-color: transparent;
  height: 50px;
  padding: 6px 15px;
  border: 1px solid #eeeeee;
  width: 100%;
  display: block;
  outline: 0;
  transition: 0.5s;
}
.widget-area .widget_search form .search-field:focus {
  border-color: #086AD8;
}
.widget-area .widget_search form button {
  position: absolute;
  right: 0;
  outline: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  z-index: 1;
  border: none;
  color: #ffffff;
  background-color: #086AD8;
  transition: 0.5s;
}
.widget-area .widget_search form button:hover {
  background-color: #212121;
  color: #ffffff;
}
.widget-area .widget_fria_posts_thumb {
  position: relative;
  overflow: hidden;
}
.widget-area .widget_fria_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}
.widget-area .widget_fria_posts_thumb .item:last-child {
  margin-bottom: 0;
}
.widget-area .widget_fria_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 80px;
  margin-right: 15px;
}
.widget-area .widget_fria_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_fria_posts_thumb .item .info {
  overflow: hidden;
  margin-top: 5px;
}
.widget-area .widget_fria_posts_thumb .item .info time {
  display: block;
  color: #666666;
  text-transform: capitalize;
  margin-top: -2px;
  margin-bottom: 3px;
  font-size: 14px;
}
.widget-area .widget_fria_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
.widget-area .widget_fria_posts_thumb .item .info .title a {
  display: inline-block;
}
.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  color: #666666;
  padding-left: 14px;
  font-size: 15px;
  font-weight: 600;
}
.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_categories ul li::before {
  background: #086AD8;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 8px;
  position: absolute;
}
.widget-area .widget_categories ul li a {
  color: #212121;
  display: block;
}
.widget-area .widget_categories ul li a:hover {
  color: #086AD8;
}
.widget-area .widget_categories ul li .post-count {
  float: right;
}
.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 12px;
}
.widget-area .tagcloud a {
  display: inline-block;
  color: #212121;
  font-weight: 600;
  font-size: 14px !important;
  padding: 6px 13px;
  border: 1px dashed #eeeeee;
  margin-top: 8px;
  margin-right: 4px;
}
.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
  color: #ffffff;
  background-color: #086AD8;
  border-color: #086AD8;
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc .article-content {
  margin-top: 30px;
}
.blog-details-desc .article-content .entry-meta {
  margin-bottom: -8px;
}
.blog-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.blog-details-desc .article-content .entry-meta ul li {
  position: relative;
  display: inline-block;
  color: #212121;
  margin-right: 21px;
}
.blog-details-desc .article-content .entry-meta ul li span {
  display: inline-block;
  color: #212121;
  font-weight: 500;
}
.blog-details-desc .article-content .entry-meta ul li a {
  display: inline-block;
  color: #666666;
}
.blog-details-desc .article-content .entry-meta ul li a:hover {
  color: #086AD8;
}
.blog-details-desc .article-content .entry-meta ul li i {
  color: #086AD8;
  margin-right: 2px;
}
.blog-details-desc .article-content .entry-meta ul li::before {
  content: "";
  position: absolute;
  top: 12px;
  right: -15px;
  width: 6px;
  height: 1px;
  background: #086AD8;
}
.blog-details-desc .article-content .entry-meta ul li:last-child {
  margin-right: 0;
}
.blog-details-desc .article-content .entry-meta ul li:last-child::before {
  display: none;
}
.blog-details-desc .article-content h3 {
  font-size: 24px;
  margin-bottom: 15px;
  margin-top: 25px;
}
.blog-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.blog-details-desc .article-content .wp-block-gallery.columns-3 li {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}
.blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}
.blog-details-desc .article-content .features-list {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 30px;
}
.blog-details-desc .article-content .features-list li {
  margin-bottom: 16px;
  position: relative;
  padding-left: 34px;
  color: #666666;
}
.blog-details-desc .article-content .features-list li i {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 40px;
  background-color: #faf5f5;
  color: #086AD8;
  transition: 0.5s;
  display: inline-block;
  font-size: 11px;
  position: absolute;
  left: 0;
  top: -2px;
}
.blog-details-desc .article-content .features-list li:hover i {
  background-color: #086AD8;
  color: #ffffff;
}
.blog-details-desc .article-content .features-list li:last-child {
  margin-bottom: 0;
}
.blog-details-desc .article-footer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.blog-details-desc .article-footer .article-tags {
  flex: 0 0 50%;
  max-width: 50%;
  color: #666666;
}
.blog-details-desc .article-footer .article-tags span {
  display: inline-block;
  color: #212121;
  font-size: 20px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.blog-details-desc .article-footer .article-tags a {
  display: inline-block;
  color: #666666;
}
.blog-details-desc .article-footer .article-tags a:hover {
  color: #086AD8;
}
.blog-details-desc .article-footer .article-share {
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}
.blog-details-desc .article-footer .article-share .social li {
  display: inline-block;
}
.blog-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 2px;
  font-weight: 500;
  color: #666666;
}
.blog-details-desc .article-footer .article-share .social li a {
  display: block;
  color: #086AD8;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: #eeeef0;
  text-align: center;
  margin-left: 5px;
  font-size: 12px;
}
.blog-details-desc .article-footer .article-share .social li a:hover {
  color: #ffffff;
  background-color: #086AD8;
  transform: translateY(-2px);
}
.blog-details-desc .post-navigation {
  margin-top: 30px;
}

blockquote, .blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
}
blockquote p, .blockquote p {
  color: #212121;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 700;
  font-size: 22px !important;
}
blockquote cite, .blockquote cite {
  display: none;
}
blockquote::after, .blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #086AD8;
  margin-top: 20px;
  margin-bottom: 20px;
}

.post-navigation {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.post-navigation .navigation-links {
  display: flex;
  flex-wrap: wrap;
}
.post-navigation .navigation-links .nav-previous {
  flex: 0 0 50%;
  max-width: 50%;
}
.post-navigation .navigation-links .nav-previous a {
  display: inline-block;
  font-weight: 600;
}
.post-navigation .navigation-links .nav-previous a i {
  margin-right: 2px;
  transition: 0.5s;
}
.post-navigation .navigation-links .nav-previous a:hover i {
  margin-right: 0;
}
.post-navigation .navigation-links .nav-next {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
}
.post-navigation .navigation-links .nav-next a {
  display: inline-block;
  font-weight: 600;
}
.post-navigation .navigation-links .nav-next a i {
  margin-left: 2px;
  transition: 0.5s;
}
.post-navigation .navigation-links .nav-next a:hover i {
  margin-left: 0;
}
.post-navigation .navigation-links div a {
  display: inline-block;
  font-weight: 400;
}

.comments-area {
  padding: 25px;
  margin-top: 30px;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background-color: #ffffff;
}
.comments-area .comments-title {
  line-height: initial;
  font-size: 23px;
  position: relative;
  margin-bottom: 30px;
}
.comments-area ol, .comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comments-area .comment-body {
  border-bottom: 1px solid #eeeeee;
  padding-left: 65px;
  color: #212121;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.comments-area .comment-body .reply {
  margin-top: 15px;
}
.comments-area .comment-body .reply a {
  border: 1px solid #ded9d9;
  color: #212121;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  font-size: 12px;
  font-weight: 600;
}
.comments-area .comment-body .reply a:hover {
  color: #ffffff;
  background-color: #086AD8;
  border-color: #086AD8;
}
.comments-area .comment-author {
  font-size: 17px;
  margin-bottom: 0.1em;
  position: relative;
  z-index: 2;
}
.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
}
.comments-area .comment-author .fn {
  font-size: 16px;
}
.comments-area .comment-author .says {
  display: none;
}
.comments-area .comment-metadata {
  margin-bottom: 0.8em;
  color: #666666;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
}
.comments-area .comment-metadata a {
  color: #666666;
}
.comments-area .comment-metadata a:hover {
  color: #086AD8;
}
.comments-area .comment-respond {
  margin-top: 30px;
}
.comments-area .comment-respond .comment-reply-title {
  margin-bottom: 0;
  position: relative;
  font-size: 23px;
}
.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  display: inline-block;
}
.comments-area .comment-respond .comment-form {
  overflow: hidden;
}
.comments-area .comment-respond .comment-notes {
  margin-bottom: 0;
  margin-top: 10px;
}
.comments-area .comment-respond .comment-form-comment {
  margin-top: 15px;
  float: left;
  width: 100%;
}
.comments-area .comment-respond label {
  display: block;
  font-weight: 400;
  color: #212121;
  margin-bottom: 5px;
}
.comments-area .comment-respond input[type=date], .comments-area .comment-respond input[type=time], .comments-area .comment-respond input[type=datetime-local], .comments-area .comment-respond input[type=week], .comments-area .comment-respond input[type=month], .comments-area .comment-respond input[type=text], .comments-area .comment-respond input[type=email], .comments-area .comment-respond input[type=url], .comments-area .comment-respond input[type=password], .comments-area .comment-respond input[type=search], .comments-area .comment-respond input[type=tel], .comments-area .comment-respond input[type=number], .comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.comments-area .comment-respond input[type=date]:focus, .comments-area .comment-respond input[type=time]:focus, .comments-area .comment-respond input[type=datetime-local]:focus, .comments-area .comment-respond input[type=week]:focus, .comments-area .comment-respond input[type=month]:focus, .comments-area .comment-respond input[type=text]:focus, .comments-area .comment-respond input[type=email]:focus, .comments-area .comment-respond input[type=url]:focus, .comments-area .comment-respond input[type=password]:focus, .comments-area .comment-respond input[type=search]:focus, .comments-area .comment-respond input[type=tel]:focus, .comments-area .comment-respond input[type=number]:focus, .comments-area .comment-respond textarea:focus {
  border-color: #086AD8;
}
.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 4px;
}
.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: #666666;
  font-weight: normal;
  position: relative;
  top: -2px;
}
.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}
.comments-area .comment-respond .form-submit input {
  background: #086AD8;
  border: none;
  color: #ffffff;
  padding: 10px 30px;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border-radius: 0;
  text-transform: capitalize;
  transition: 0.5s;
  font-weight: 700;
  font-size: 14px;
}
.comments-area .comment-respond .form-submit input:hover, .comments-area .comment-respond .form-submit input:focus {
  color: #ffffff;
  background-color: #212121;
}

/*================================================
Fooetr Area CSS
=================================================*/
.footer-section {
  background-color: #f5f3f3;
}

.subscribe-area {
  margin-bottom: 50px;
}
.subscribe-area .subscribe-content h2 {
  font-size: 35px;
  margin-bottom: 0;
}
.subscribe-area .newsletter-form {
  position: relative;
}
.subscribe-area .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border: none;
  height: 60px;
  padding-left: 25px;
  border-radius: 10px;
  outline: 0;
  color: #212121;
}
.subscribe-area .newsletter-form .input-newsletter::placeholder {
  color: #666666;
}
.subscribe-area .newsletter-form button {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #086AD8;
  color: #ffffff;
  border: none;
  height: 50px;
  padding: 0 30px 1px;
  border-radius: 10px;
  transition: 0.5s;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
}
.subscribe-area .newsletter-form button:hover {
  background-color: #10dce8;
  color: #ffffff;
}

.single-footer-widget {
  margin-bottom: 30px;
}
.single-footer-widget .footer-heading {
  margin-bottom: 20px;
}
.single-footer-widget .footer-heading h3 {
  font-size: 22px;
}
.single-footer-widget .footer-social {
  padding-left: 0;
  margin-bottom: 0;
}
.single-footer-widget .footer-social li {
  display: inline-block;
  margin-right: 10px;
  list-style-type: none;
}
.single-footer-widget .footer-social li a {
  display: block;
  width: 35px;
  height: 35px;
  background-color: #e5e3e3;
  border-radius: 4px;
  line-height: 35px;
  text-align: center;
  color: #212121;
}
.single-footer-widget .footer-social li a:hover {
  background-color: #086AD8;
  color: #ffffff;
}
.single-footer-widget .footer-social li a .flaticon-facebook, .single-footer-widget .footer-social li a .flaticon-twitter, .single-footer-widget .footer-social li a .flaticon-pinterest {
  position: relative;
  top: 0.5px;
}
.single-footer-widget .footer-social li:last-child {
  margin-right: 0;
}
.single-footer-widget .footer-quick-links {
  padding-left: 0;
  margin-bottom: 0;
}
.single-footer-widget .footer-quick-links li {
  list-style-type: none;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 600;
}
.single-footer-widget .footer-quick-links li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .footer-info-contact {
  position: relative;
  padding-left: 40px;
  line-height: 1.8;
  margin-bottom: 15px;
}
.single-footer-widget .footer-info-contact:last-child {
  margin-bottom: 0;
}
.single-footer-widget .footer-info-contact i {
  position: absolute;
  left: 0;
  font-size: 25px;
  color: #086AD8;
}
.single-footer-widget .footer-info-contact h3 {
  margin-bottom: 3px;
  font-size: 18px;
}
.single-footer-widget .footer-info-contact span {
  font-size: 15px;
}

.copyright-area {
  background-color: #212121;
  padding-top: 25px;
  padding-bottom: 25px;
}
.copyright-area .copyright-area-content p {
  color: #ffffff;
}
.copyright-area .copyright-area-content p i {
  position: relative;
  top: 1px;
}
.copyright-area .copyright-area-content p a {
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
}
.copyright-area .copyright-area-content p a:hover {
  color: #086AD8;
}
.copyright-area .copyright-area-content ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  text-align: right;
}
.copyright-area .copyright-area-content ul li {
  display: inline-block;
  position: relative;
  color: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
}
.copyright-area .copyright-area-content ul li a {
  color: #ffffff;
  display: inline-block;
}
.copyright-area .copyright-area-content ul li a:hover {
  color: #086AD8;
}
.copyright-area .copyright-area-content ul li::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -11px;
  width: 1px;
  height: 14px;
  background-color: #ffffff;
}
.copyright-area .copyright-area-content ul li:last-child {
  margin-right: 0;
}
.copyright-area .copyright-area-content ul li:last-child::before {
  display: none;
}
.copyright-area .copyright-area-content ul li:first-child {
  margin-left: 0;
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 15px;
  color: #ffffff;
  background-color: #086AD8;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  opacity: 0;
  visibility: hidden;
  transition: 0.9s;
  border-radius: 50%;
  border: 1px solid #086AD8;
}
.go-top.active {
  top: 98%;
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}
.go-top i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: 0.5s;
  font-size: 30px;
}
.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}
.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #212121;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  border-radius: 50%;
}
.go-top:hover, .go-top:focus {
  color: #ffffff;
}
.go-top:hover::before, .go-top:focus::before {
  opacity: 1;
  visibility: visible;
}
.go-top:hover i:first-child, .go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:hover i:last-child, .go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.projects-wrap-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.projects-wrap-area .container-fluid {
  overflow: hidden;
  left: calc((100% - 1320px) / 2);
  position: relative;
}

.projects-wrap-content {
  padding-right: 70px;
}
.projects-wrap-content span {
  display: block;
  color: #2EC7C8;
  text-transform: uppercase;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 15px;
  font-family: var(--optionalFontFamily);
}
.projects-wrap-content h2 {
  margin-bottom: 15px;
  font-size: 45px;
  font-weight: 700;
}
.projects-wrap-content p {
  margin-bottom: 0;
}
.projects-wrap-content .projects-wrap-btn {
  margin-top: 25px;
}
.projects-wrap-content .projects-wrap-btn .btn-primary::before {
  background: #2EC7C8;
}
.projects-wrap-content .projects-wrap-btn .btn-primary::after {
  background: var(--mediumPurpleColor);
}

.single-projects-card {
  margin-bottom: 30px;
  transition: var(--transition);
  background-color: var(--whiteColor);
}
.single-projects-card a img {
  transition: var(--transition);
}
.single-projects-card .projects-content {
  transition: var(--transition);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  padding: 30px 35px;
}
.single-projects-card .projects-content span {
  display: block;
  color: var(--paragraphColor);
  margin-bottom: 12px;
  font-size: 13.8px;
  font-weight: 600;
}
.single-projects-card .projects-content h3 {
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 700;
}
.single-projects-card .projects-content p {
  margin-top: 15px;
  margin-bottom: 0;
}
.single-projects-card .projects-content .read-more-btn {
  margin-top: 15px;
  color: var(--eggBlueColor);
}
.single-projects-card .projects-content a:hover {
  color: #086AD8;
}
.single-projects-card:hover a img {
  opacity: 0.75;
}

.projects-wrap-slides.owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
}
.projects-wrap-slides.owl-theme .owl-nav [class*=owl-] {
  display: block;
  margin: 0;
  text-align: center;
  height: 50px;
  line-height: 50px;
  width: 50px;
  background: var(--whiteColor);
  color: var(--mainColor);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0;
  transition: var(--transition);
  position: relative;
  z-index: 1;
  font-weight: 600;
  font-size: 18.5px;
  font-family: var(--optionalFontFamily);
}
.projects-wrap-slides.owl-theme .owl-nav [class*=owl-] i {
  position: relative;
  top: -1px;
}
.projects-wrap-slides.owl-theme .owl-nav [class*=owl-] i::before {
  font-size: 16.5px;
}
.projects-wrap-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--whiteColor);
  background-color: var(--mediumPurpleColor);
}

a {
  text-decoration: none;
}
a:hover, a:focus, a.active {
  color: #ffffff;
}